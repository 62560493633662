<template>
  <div id="blog">
    <RecentPosts title="Blog" section_info />

    <b-button id="blog_view_more" to="/blog">
      VIEW MORE
    </b-button>
  </div>
</template>

<script>
import RecentPosts from '@/components/RecentPosts'

export default {
  name : 'Blog',
  components : {RecentPosts}
}
</script>

<style scoped>
#blog{
  margin: 25px 0;
  padding: 50px;
  background: var(--theme-color7);
}

#main_layout.xs #blog{
  padding: 15px;
}

#blog_view_more{
  margin-top: 15px;
  margin-left: 25px;
  background: var(--theme-color0);
  color: var(--theme-color9);
  border: 1px solid white;
  border-radius: 41px;
  font-weight: bold;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main_layout.sm #blog_view_more,
#main_layout.xs #blog_view_more{
  margin: auto;
}

#main_layout.xs #blog_view_more{
  margin-left: unset;
}
</style>

<style>
#blog .section_info{
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--theme-color10);
}

#blog #recent_posts .h1{
  font-weight: 900;
  font-size: 72px;
  margin-bottom: unset;
}

#main_layout.sm #blog #recent_posts .h1,
#main_layout.xs #blog #recent_posts .h1{
  font-size: 48px;
}
</style>
