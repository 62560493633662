<template>
  <div id="links">
    <img v-if="!mq_md" id="logo" src="@/assets/images/icons/logo-alt.webp" alt="logo" />

    <ul>
      <li><router-link to="/">Game</router-link></li>

      <li v-if="download_enabled">
        <router-link to="/download">Download</router-link>
      </li>

      <li><router-link to="/gallery">Gallery</router-link></li>
      <li><router-link to="/faq">FAQ</router-link></li>
      <li><router-link to="/blog">Blog</router-link></li>
      <li><router-link to="/#contact">Contact</router-link></li>
    </ul>
  </div>
</template>

<script>
import DownloadToggler from '../../mixins/download_toggler'

export default {
  name: 'Links',

  mixins : [DownloadToggler]
}
</script>

<style scoped>
#links{
  flex-basis: 40%;
  display: flex;
  justify-content: flex-start;
}

#main_layout.md #links,
#main_layout.sm #links,
#main_layout.xs #links{
  flex-direction: column;
  align-items: center;
}

#logo{
  height: 70px;
  width: 112px;
}

ul{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 0;
}

#main_layout.md ul{
  padding-left: 0;
}

#main_layout.sm ul,
#main_layout.xs ul{
  display: unset;
  padding-left: 0;
}

li{
  list-style-type: none;
  padding: 10px;
  text-align: center;
}

a{
  font-weight: 500;
  color: var(--theme-color0);
}

a:hover{
  text-decoration: none;
}
</style>
