<template>
  <div>
    <div id="leader">By sending XRP to:</div>

    <div id="destination">
      <div class="profile_modal_label">Address:</div>
      <CopyBox>{{xrp_address}}</CopyBox>
    </div>

    <div id="destination_tag">
      <div class="profile_modal_label">Destination Tag:</div>
      <CopyBox>{{account_destination_tag}}</CopyBox>
    </div>

    <WarnBox>
      Both the <b>address and destination tag</b> are mandatory to purchase LCC!
    </WarnBox>

    <div id="credit">
      You will be credited <b>{{lcc_per_xrp.toFixed(2)}} LCC per XRP</b>
    </div>
  </div>
</template>

<script>
import CopyBox from '@/components/CopyBox'
import WarnBox from '@/components/WarnBox'
import Authentication from '@/mixins/authentication'

import lc from '@/config/lc'

export default {
  name : 'ByXRP',

  mixins : [Authentication],

  components : {CopyBox, WarnBox},

  computed : {
    xrp_address () {
      return lc.wallets.purchase;
    },

    lcc_per_xrp () {
      return this.$store.state.meta.xchg_rate_xrpusd / lc.lcc_price;
    }
  }
}
</script>

<style scoped>
#leader{
  font-weight: bold;
  font-size: 24px;
}

#destination{
  margin-bottom: 15px;
}

#destination .copy_box{
  margin-top: 8px;
}

#destination .copy_box.xs{
  margin-left: 5px;
}

#destination_tag{
  margin-bottom: 15px;
}

#destination_tag .copy_box{
  margin-top: 8px;
}

#destination_tag .copy_box.xs{
  margin-left: 5px;
}

#credit{
  margin-top: 15px;
}
</style>
