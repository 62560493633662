import Posts from '../assets/data/posts.json'
import config from '../config/config'

export default {
  props : {
    path : String
  },

  computed : {
    route_path : function(){
      var path = this.$route.path.split("/")
      return path[path.length-1]
    },

    inferred_path : function(){
      return this.path || this.route_path;
    },

    post : function(){
      return Posts.find(function(post){
        return post.path == this.inferred_path;
      }.bind(this))
    },

    parsed_date : function(){
      return new Date(Date.parse(this.post.date));
    },

    date_str : function(){
      return this.parsed_date.toLocaleString('default', { month: 'long' }) + " " +
             this.parsed_date.getDate() + ", " + this.parsed_date.getFullYear();
    },

    url_path : function(){
      return "/post/" + this.post.path
    },

    title : function(){
      return this.post.title;
    },

    url : function(){
      return config.URL + this.url_path;
    },

    post_img : function(){
      return require('@/assets/images/posts/' + this.inferred_path + '.webp')
    },

    post_img_mq : function(){
      return require('@/assets/images/posts/_mq/' + this.inferred_path + '-' + this.$mq + '.webp')
    }
  }
}
