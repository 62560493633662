import util   from '../util'
import config from '../config/config'

// TODO: move all request callbacks to invokers (with few exceptions)

export default {
  computed : {
    backend_uri () {
      return config.BACKEND + "/" + config.VERSION;
    }
  },

  methods : {
    get_meta () {
      this.$htttp().get(this.backend_uri + "/meta")
                   .then(function(response){
                     this.$store.commit('set_meta', response.body);

                   }.bind(this)).catch(function(err){
                     const msg = util.capitalize(err.body.error)

                     this.$store.commit('set_global_result', {
                       id : 'meta_error',
                       message : 'Could not get game data: ' + msg,
                       is_error : true
                     })

                     this.$bvModal.show('result_modal');
                   }.bind(this))
    },

    login (email, password) {
      const params = {email, password};
      return this.$htttp().post(this.backend_uri + "/login", params);
    },

    logout (){
      this.$htttp().delete(this.backend_uri + "/logout", this.auth_header)
                   .then(function(){
                     this.$removeCookie("authToken")
                     this.$store.commit('clear_user')

                   }.bind(this)).catch(function(){
                     this.$removeCookie("authToken")
                     this.$store.commit('clear_user')

                   }.bind(this))
    },

    register (params) {
      return this.$htttp().post(this.backend_uri + "/register", params);
    },

    get_user () {
      return this.$htttp().get(this.backend_uri + "/user?accounts=true&associations=true&bids=true&corporations=true", this.auth_header)
                          .then(function(response){
                            this.$store.commit('set_user', response.body);

                          }.bind(this)).catch(function(err){
                             this.logout();

                             const msg = util.capitalize(err.body.error)

                             this.$store.commit('set_global_result', {
                               id : 'get_user_error',
                               message : 'Could not load user: ' + msg,
                               is_error : true
                             })

                             this.$bvModal.show('result_modal');
                          }.bind(this))
    },

    update_user (user) {
      return this.$htttp().put(this.backend_uri + "/user", user, this.auth_header);
    },

    confirm_email (params){
      return this.$htttp().put(this.backend_uri + "/confirm", params);
    },

    associate_account (account_id){
      var params = {account_id}
      return this.$htttp().post(this.backend_uri + "/account/associate",
                                            params, this.auth_header);
    },

    withdraw_lcc (lcc, account, destination_tag){
      var params = {lcc, account};
      if(destination_tag) params.destination_tag = destination_tag;

      return this.$htttp().post(this.backend_uri + "/blockchain/withdraw",
                                              params, this.auth_header);
    },

    confirm_lcc_withdrawl (code){
      return this.$htttp().post(this.backend_uri + "/blockchain/withdraw",
                                              {code}, this.auth_header);
    },

    reset_password_confirm (email){
      return this.$htttp().put(this.backend_uri + "/reset", {email});
    },

    reset_password (code, password){
      return this.$htttp().put(this.backend_uri + "/reset", {code, password});
    },

    purchase (params){
      return this.$htttp().post(this.backend_uri + "/purchase",
                                params, this.auth_header);
    },

    get_auction (auction, building){
      let uri = this.backend_uri + "/auction?" + (auction ? ("auction="+auction) : ("building="+building));
      return this.$htttp().get(uri)
    },

    place_bid (params){
      return this.$htttp().post(this.backend_uri + "/auction/bid", params, this.auth_header)
    },

    get_status () {
      return this.$htttp().get(this.backend_uri + "/status", this.auth_header);
    },

    get_user_status (user) {
      return this.$htttp().get(this.backend_uri + "/status?user=" + user, this.auth_header);
    },

    get_account_status (account) {
      return this.$htttp().get(this.backend_uri + "/status?account=" + account, this.auth_header);
    },

    form_corporation (title){
      return this.$htttp().post(this.backend_uri + "/corporation/form", {title}, this.auth_header);
    },

    leave_corporation (title){
      return this.$htttp().delete(this.backend_uri + "/corporation/leave", this.auth_header);
    },

    invite_to_corporation (corporation, user) {
      return this.$htttp().put(this.backend_uri + "/corporation/invite",
        { corporation, user }, this.auth_header);
    },

    join_corporation (corporation){
      return this.$htttp().put(this.backend_uri + "/corporation/join",
        {corporation}, this.auth_header)
    },

    kick_from_corporation (corporation, user){
      return this.$htttp().put(this.backend_uri + "/corporation/kick",
        { corporation, user }, this.auth_header);
    }
  }
}
