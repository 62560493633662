<template>
  <b-modal id="result_modal"
           ref="result_modal"
           ok-title="Close"
           ok-only
           centered
           :footer-class="{error : is_error}">
    <ModalWrapper>
      <img src="@/assets/images/icons/alert.webp"   v-if="is_error"        alt="error"   />
      <img src="@/assets/images/icons/warning.webp" v-else-if="is_warning" alt="warning" />
      <img src="@/assets/images/icons/check.webp"   v-else                 alt="success" />

      <div>
      {{message}}
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from './ModalWrapper'

export default{
  name : "ResultModal",

  components : {
    ModalWrapper
  },

  computed : {
    result () {
      return this.$store.state.global_result;
    },

    message () {
      return this.result.message;
    },

    is_error () {
      return this.result.is_error;
    },

    is_warning () {
      return this.result.is_warning;
    }
  },

  unmounted () {
    this.$store.commit('clear_global_result');
  }
}
</script>

<style>
#result_modal {
  text-align: center;
  font-family: var(--theme-font1);
  font-weight: bold;
  font-size: 22px;
}

#result_modal___BV_modal_content_{
  border-radius: 25px;
  padding: 25px;
}

#result_modal___BV_modal_header_{
  display: none;
}

#result_modal___BV_modal_body_{
  padding-top: 25px;
}

#result_modal___BV_modal_body_ img{
  margin-bottom: 25px;
}

#result_modal___BV_modal_footer_{
  border: none;
}

#result_modal___BV_modal_footer_ button{
  width: 100%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

#result_modal___BV_modal_footer_.error button{
  background: var(--theme-color16);
  border: 1px solid var(--theme-color16);
  color: var(--theme-color0);
}
</style>
