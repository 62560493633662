import lc from '../config/lc'

export default {
  data : function(){
    return {
      common_sections : [
{
  id : "create-account",
  priority : 21,
  title : "Create Account & Login",
  content :
`<p>Create a new Ledger City Account <a href='/register'>here</a></p>

<p>You will be prompted for a few basic details (email, password).</p>

<p>Once supplied you will be sent a confirmation email. Click the link in it and you are ready to go!</p>

<p>Enter you email and password and click <b>Login</b> on the <a href="/">home page</a> or in the game client to access your account.</p>`,
},


{
  id : "forgot-password",
  priority : 21,
  title : "I forgot my password",
  content :
`<p>Enter your email and click <a href='/'><b>Forgot Password</b></a></p>

<p>Check your email inbox for a password reset email.</p>

<p>Click on the provided link and enter a new password.</p>`
},

{
  id : 'lcc',
  priority : 21,
  title : 'Ledger City Coin (LCC)',
  content :
`<p>Ledger City Coin (LCC) is the official in-game currency.</p>

<p>It is issued from the following wallet:</p>

<p><b>${lc.wallets.issuer}</b></p>

<p>Extend your trust line to that!</p>
`
},

{
  id : 'trustline-setup',
  priority : 41,
  title : "Setting up a LCC TrustLine",
  content :
`<p>The instructions to set up a trust line varies on a wallet to wallet basis.</p>

<p>Read how to setup a trustline using Xumm <a href="https://support.xumm.app/hc/en-us/articles/360018166659-How-do-we-add-another-asset-a-token-to-an-account-">here</a></p>

<p>Read how to setup a trustline using the XRP Toolkit <a href="https://docs.xrptoolkit.com/set-trust-lines">here</a>`
},


{
  id : "purchasing-lcc",
  disabled: true,
  priority : 41,
  title : "Purchasing LCC",
  content :

`<p><a href="#create-account">Login</a> to your Ledger City Game account</p>

<p>Click on <a href="/profile">My Profile</a><p>

<p>Click <b>Purchase</b> next to <i>LCC</i></p>

<p>Two payment operations are available</p>

<p><b>1. Credit Card</b></p>

<ol>
<li>Enter Credit Card Details</li>
<li>Specify the amount of LCC to purchase</li>
<li>Click <i>Purchase</i></li>
</ol>

<p>You will be immediately charged the specified amount. LCC will be immediately credited to your account</p>


<p><b>2. XRP</b></p>

<ol>
<li>Click on the <u>XRP</u> tab</li>
<li>Open your XRP wallet and create a payment transaction</li>
<li>Copy the payment address to the payment</li>
<li>Copy the destination tag to the payment. <b>Both the address and destination tag are mandatory!</b></li>
<li>Enter the amount of XRP to send using the specified LCC/XRP conversion rate</li>
<li>Click <i>Send</i> to send the payment</li>
</ol>

<p>As soon as we detect the payment to the address and destination tag on the blockchain, we will credit the corresponding amount of LCC to your account.</p>

<p>Once purchased, you can spend LCC to customize your building in Ledger City.</p>`
},

{
  id : "withdrawing-lcc",
  disabled: true,
  priority : 41,
  title : "Withdrawing LCC",
  content :
`<p>Before you can withdraw LCC to your wallet, you need to <a href="#trustline-setup">setup a trust line</a> Ledger City's <a href="#lcc">issuing address</a>.

<ol>
<li><a href="#create-account">Login</a> to your account</li>
<li>Click <a href="/profile">My Profie</a></li>
<li>Click on <b>Withdraw</b> next to LCC</li>
<li>Enter the wallet which you would like to withdraw your LCC to</li>
<li>Optionally specify a destination tag</li>
<li>Select the amount of LCC to withdraw</li>
<li>Click <i>Withdraw</i></li>
</ol>

<p>A confirmation email will be sent to you. Click on the link contained within to finalize your withdrawl</p>

<p><b>Warning!</b> Once confirmed, this cannot be undone!</p>

<p>Be <b>absolutely certain</b> you are withdrawing to the right address and destination tag!</p>`
},

{
  id : "claim-account",
  priority : 41,
  title : "Claim your XRP account",
  content :
`<p>To customize your property in Ledger City, you need to claim the corresponding account first:</p>

<ol>
<li><a href="#create-account">Login</a> to your game account</li>
<li>Click on <a href='/profile'>"My Profile"</a></li>
<li>Click on "My Property"</li>
<li>Click <b>"Claim Account"</b></li>
<li>Enter the address of the account to claim</li>
</ol>

<p>Two small cryptocurrency amounts will be generated.</p>

<p>Send <u>two separate payments</u> for those amounts from that wallet to Ledger City's:</p>

<p><b>${lc.wallets.purchase}</b></p>

<p>Once we receive those  payments, the game will assign ownership of that wallet to you.</p>

<p>You will receive an initial amount of ${lc.initial_lcc} LCC to spend in the game.</p>`
},

{
  id : "upcoming-features",
  priority : 61,
  title : "Upcoming features",
  content :
`<p>Some features currently in development:</p>

<ul>
<li>NFT art galleries</li>
<li>Community spaces</li>
<li>Building Interiors</li>
<li>Ledger Object Representations (Escrows, Offers, IOUs)</li>
<li>Content creator</li>
<li>Combat, racing, and e-sports</li>
<li>Missions and storylines</li>
<li>Much more!</li>
</ul>

<p>Sign up for the <a href="#more-info">Mailing List</a> to keep up to date with all the latest happenings!`
}

      ],

////////////////////////////

      faq_sections : [
{
  id : "what-is-ledger-city",
  priority : 1,
  title : "What is Ledger City?",
  content :
`<p>Ledger City is a game based on the XRP blockchain wherein accounts are represented as buildings in a 3D city.</p>

<p>You as the player may claim ownership of your property through a simple verification process.</p>

<p>After verification, you can customize the appearance of your property via collectable and customizable NFTs placed on the building.</p>`
},

{
  id : "system-requirements",
  priority : 1,
  title : "What are the minimum system requirements?",
  content :
`<p>To play Ledger City you must have at least:</p>
<p>A PC with the Windows Operating System, an Intel i3 2.7GHz+ processor, 4 GB of RAM, and a nVidia 1050 GPU;</p>
<p>or an Apple Mac system with OSX Sierra or later, a 2.7GHz+ processor, 4 GB of RAM, and GPU</p>
<p>or an Android Mobile Device (phone, tablet, etc).</p>`
},

{
  id : 'more-info',
  priority : 1,
  title : 'Finding out more information',
  content :
`<p>Sign up for the mailing list at the bottom of this page.</p>

<p>Not only will you be the first to receive news and announcements, you will be eligible promotions and special events exclusive to mailing list subscribers!</p>`
},

{
  id : "supported-wallets",
  priority : 1,
  title : "Supported Wallets",
  content :
`<p>All <i>self-custodial</i> wallets (those which you hold and manage your own private keys) will be represented in Ledger City.</p>

<p>These include, <i>but are not limited to</i>:</p>

<ul>
<li><a href="https://www.ledger.com/">Ledger</a> Hardware Wallet</li>
<li><a href="https://xumm.app/">Xumm</a> Wallet</li>
<li><a href="https://bifrostwallet.com/">Bifrost</a> Wallet</li>
<li><a href="https://dcentwallet.com/?lang=en">D'Cent</a> Wallet</li>
<li><a href="https://www.blockchain.com/wallet">Blockchain.com</a></li>
<li><a href="https://www.ellipal.com/">Ellipal Wallet</a></li>
<li><a href="https://trustwallet.com">Trust Wallet</a></li>
<li><a href="https://atomicwallet.io">Atomic Wallet</a></li>
<li><a href="https://trezor.io/">Trezor Wallet</a></li>
<li>And many more</li>
</ul>

<p>By contrast the following are <b>not</b> supported, among others as they are non-custodial:</p>
<ul>
<li>Nexo Wallet</li>
<li>crypto.com Wallet</li>
<li>Uphold Wallet</li>
<li>Binance Wallet</li>
<li>BitGo Wallet</li>
<li>Various others</li>
</ul>

<p><b>MetaMask</b> is <b>not</b> supported at this time as it does not support XRP</p>
`
},

{
  id : "lcc-release",
  priority : 31,
  title : 'When will LCC be available?',
  content :
`<p>Due to <a href="https://www.sec.gov/corpfin/pocketful-quarters-inc-072519-2a1" target="_blank">SEC regulation</a>, LCC will be available after the release of Ledger City.</p>`
},

{
  id : 'airdrop',
  priority : 31,
  title : 'Will you be doing a LCC airdrop?',
  content :
`<p>No</p>`
},

{
  id : 'play-to-earn',
  priority : 31,
  title : 'Is Ledger City a "play to earn" game?',
  content :
`<p>No</p>`
},

{
  id : 'casino',
  priority : 31,
  title : 'Can I create a casino in Ledger City?',
  content :
`<p>Due to the wide variety of on-line and off-line gambling prohibitions in many jurisdictions around the world, we cannot endorse Ledger City being used for games of chance or other gambling-related activities.</p>

<p>Until we have the ability to restrict access to players on a jurisdiction-by-jurisdiction basis, gambling is prohibited in Ledger City.</p>`
},

{
  id : 'chat',
  priority : 31,
  title : 'Do you have a discord, telegram, etc...?',
  content :
`<p>Not at the current time. The community is encouraged to send questions to Ledger City via the <a href="/#contact">Contact Form</a> on this website and via our <a href="https://twitter.com/LedgerCityGame">Twitter</a>.</p>`
},

{
  id : 'how-do-i',
  priority : 31,
  title : 'How do I...?',
  content :
`<p>Step by step guides to do the following will be published in the near future</p>

<ul>
<li>Create an account and login</li>
<li>Play the game</li>
<li>Purchase, withdraw, and deposit LCC</li>
<li>Claim and customize your property</li>
<li>And much more</li>
</ul>`
}

      ],

////////////////////////////

      guide_sections : [

{

  id : "getting-started",
  priority : 1,
  title : "Getting Started",
  content :
`<p><a href='/download'>Download</a> and run the game.</p>

<p>You will be immediately dropped into Ledger City. Walk or fly around to explore! (see <a href='#navigation'>Navigation</a> )</p>

<p>Click on buildings to view the accounts associated with those building</p>

<p>When you are ready, log into the game to claim ownership of your buildings and customize them! (more details below)</p>`,
},

{
  id : "installation",
  priority : 1,
  title : "Installation",
  content :
`<p>Ledger City is supported on Windows PC and MacOS platforms.</p>

<p>Download the setup executable on Windows and run to install and launch Ledger City.</p>

<p>On MacOS, double click on the DMG file and drag the Ledger City icon to your 'Applications' folder. Double click on the icon there to launch.</p>

<p>On MacOS, if you get the error: <b>Apple cannot check it for malicious software</b>, right click on the Icon, and click 'Open'. Then click 'Open' again in the next dialog.</p>`
},

{
  id : "navigation",
  priority : 1,
  title : "Navigating Ledger City",
  content :
`<p>Use the mouse and keyboard to walk and fly around the city.</p>

<p>Press and hold <b>W</b> to move forward, <b>S</b> to move back, <b>A</b> to move left, and <b>D</b> to move right</p>

<p>Hold the left mouse button and move the cursor around to look around</p>

<p>When walking click 'F' to fly. When flying click 'F' to land</p>

<p>Right click on a specific building to focus on it and view it close up</p>`
},

{
  id : "collectable-assets",
  priority : 51,
  title : "Purchasing Collectable Assets",
  content :
`<p>Click on the building you wish to customize in the game.</p>

<p>Click on 'Customize'</p>

<p>The asset store will be launched, click on an asset and hover over glowing zones on the building to preview it.</p>

<p>Some assets can be rotated by pressing and holding the <b>R</b> hey</p>

<p>You can view the building from different angles with the mouse and <b>W</b> and <b>S</b> keys</p>

<p>Click on the location which you wish to place the asset to purchase it</p>

<p>Confirm your purchase. Your LCC balance will be deducted to purchase the asset.</p>`
},

{
  id : "removing-assets",
  priority : 51,
  title : "Removing Assets",
  content :
`<p>Click on an asset you'd like to remove and hold for 2 seconds.</p>

<p>Confirm you would like to remove this asset.</p>`
}
      ]
    }
  }
}
