<template>
  <b-container id="property_tab">
    <Accounts />

    <!--<AuctionBids />-->

    <AssociationRequests />

    <Claim />
  </b-container>
</template>

<script>
import Authentication from '@/mixins/authentication'

import Accounts from './property/Accounts'
//import AuctionBids from './property/AuctionBids'
import AssociationRequests from './property/AssociationRequests'
import Claim from './property/Claim'

export default {
  name: 'ProfileTab',

  mixins : [Authentication],

  components : {
    Accounts,
    //AuctionBids,
    AssociationRequests,
    Claim
  }
}
</script>

<style scoped>
#property_tab{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>

<style>
.no_property_records{
  color: var(--theme-color16);
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.no_property_records,
.property_records{
  margin-bottom: 25px;
}

.property_header{
  font-weight: bold;
  font-size: 24px;
  text-align: center;
}

.property_column_header{
  font-weight: bold;
  text-decoration: underline;
}
</style>
