<template>
  <div class="error_box">
    <img src="@/assets/images/icons/alert-icon.webp" alt="alert" />

    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default{
  name : 'ErrorBox'
}
</script>

<style scoped>
.error_box{
  background: var(--theme-color15);
  color: var(--theme-color16);
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.error_box img{
  width: 25px;
  height: 25px;
  margin: 0 15px;
}
</style>
