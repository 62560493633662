<template>
  <footer>
    <div id="row1">
      <Links />
      <Subscribe />
    </div>

    <div id="row2">
      <Social />
      <Copyright />
    </div>
  </footer>
</template>

<script>
import Links     from './footer/Links'
import Subscribe from './footer/Subscribe'
import Social    from './footer/Social'
import Copyright from './footer/Copyright'

export default {
  name: 'MainFooter',

  components : {
    Links,
    Subscribe,
    Social,
    Copyright
  }
}
</script>

<style scoped>
footer > div{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#main_layout.sm footer > div,
#main_layout.xs footer > div{
  flex-direction: column;
}

#row1{
  background: var(--theme-color12); 
  padding: 0 25px;
}

#main_layout.sm #row1,
#main_layout.xs #row1{
  padding: 5px;
}

#row2{
  background: var(--theme-color9); 
  padding: 5px 25px 0 25px;
}
</style>
