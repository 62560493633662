<template>
  <MainLayout :section="section">
    <div id="center_layout">
      <div id="section">
        <slot></slot>
      </div>
    </div>
  </MainLayout>
</template>

<script>
import MainLayout from './MainLayout'

export default {
  name: 'CenterLayout',

  components: {
    MainLayout
  },

  props : {
    section : {
      type : String,
      required : true
    }
  }
}
</script>

<style scoped>
#center_layout{
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('~@/assets/images/icons/center-layout.webp');
  background-size: 100% 100%;
}

#section{
  flex-basis: 33%;
  background-color: var(--theme-color0);
  border-radius: 16px;
}

#main_layout.md #section{
  flex-basis: 53%;
}

#main_layout.sm #section{
  flex-basis: 73%;
  margin: 25px 0;
}

#main_layout.xs #section{
  flex-basis: 95%;
  margin: 25px 0;
}
</style>
