<template>
  <div class="copy_box" :class="$mq">
    <div ref="content">
      <slot/>
    </div>

    <img id="copy_img"
         src="@/assets/images/icons/copy.webp"
         @click="copy"
         alt="copy" />

    <b-tooltip :show.sync="show_tooltip"
               target="copy_img"
               placement="top"
               offset="50"
               triggers="">
      Copied!
    </b-tooltip>
  </div>
</template>

<script>
export default{
  name : 'CopyBox',

  data () {
    return {
      show_tooltip : false
    };
  },

  methods : {
    copy () {
      this.show_tooltip = false;

      const text = this.$refs.content.innerText;
      navigator.clipboard
               .writeText(text)
               .then(function() {
                 this.show_tooltip = true;

                 setTimeout(function(){
                   this.show_tooltip = false
                 }.bind(this), 1500);
               }.bind(this))
    }
  }
}
</script>

<style scoped>
.copy_box{
  background: var(--theme-color1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
}

.copy_box.xs{
  padding: 5px;
  font-size: 12px;
}

.copy_box img{
  border-left: 1px solid var(--theme-color14);
  width: 40px;
  height: 30px;
  padding-left: 10px;
  cursor: pointer;
}

.copy_box.xs img{
  padding-left: 5px;
}
</style>
