<template>
  <b-row no-gutters>
    <b-col>
      <div class="header">Password</div>

      <div class="content" v-if="!edit">
        *******
      </div>

      <div class="input mt-1" v-else>
        <b-form-input size="lg" type="password"
                      v-model="specified_password"
                      placeholder="Enter password" />
      </div>

      <div class="error_placeholder" v-if="!mq_xs && no_password_errors"/>

      <div class="error" v-if="weak">
        Weak password
      </div>
    </b-col>

    <b-col :cols="mq_xs ? 12 : 4" v-if="edit">
      <div class="header" style="color: var(--theme-color0)">Hidden</div>

      <div class="input mt-1">
        <b-form-input size="lg" type="password"
                      v-model="specified_password_confirmation"
                      placeholder="Confirm password" />
      </div>

      <div class="error_placeholder" v-if="!mq_xs && no_password_confirmation_errors"/>

      <div class="error" v-if="mismatch">
        Passwords do not match
      </div>
    </b-col>

    <b-col cols="2" v-if="edit"
                    id="update_col"
                    class="button_col">
      <b-button class="update"
            :disabled="update_disabled"
            @click="update">
        Update
      </b-button>
    </b-col>

    <b-col cols="2"
           id="edit_col" class="button_col">
      <b-button :class="{edit : !edit,
                       cancel :  edit}"
                @click="toggle_edit">
        <template v-if="!edit">
          Edit
          <img src="@/assets/images/icons/edit.webp" alt="edit" />
        </template>

        <template v-else>
          Cancel
        </template>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name: 'Password',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  data () {
    return {
      edit : false,
      specified_password : null,
      specified_password_confirmation : null
    }
  },

  computed : {
    password_is_specified () {
      return !!this.specified_password;
    },

    password_confirmation_is_specified () {
      return !!this.specified_password_confirmation;
    },

    weak () {
      return this.password_is_specified &&
             util.is_weak_password(this.specified_password);
    },

    mismatch () {
      return this.password_is_specified &&
             this.password_confirmation_is_specified &&
             this.specified_password != this.specified_password_confirmation;
    },

    any_password_errors () {
      return this.weak;
    },

    no_password_errors () {
      return !this.any_password_errors;
    },

    any_password_confirmation_errors(){
      return this.mismatch;
    },

    no_password_confirmation_errors () {
      return !this.any_password_confirmation_errors;
    },

    update_disabled (){
      return !this.password_is_specified ||
             !this.password_confirmation_is_specified ||
              this.any_password_errors ||
              this.any_password_confirmation_errors;
    }
  },

  methods : {
    toggle_edit(){
      this.edit = !this.edit;
      this.specified_password = null;
      this.specified_password_confirmation = null;
      this.$store.commit('clear_global_result');
    },

    update(){
      this.update_user({password : this.specified_password})
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    success(){
      this.toggle_edit();
    },

    failed(err){
      this.toggle_edit();

      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'update_password_error',
        message : 'Could not update user: ' + msg,
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
#main_layout.xs .row{
  flex-direction: column;
}

#main_layout.xs #update_col,
#main_layout.xs #edit_col {
  align-self: flex-end;
  margin-top: 5px;
}

.input{
  padding-right: 15px;
}

#main_layout.xs .input{
  padding: unset;
}
</style>
