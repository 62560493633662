module.exports = {
  // URL which site is hosted
  URL : "https://ledgercitygame.com",

  // Contact email
  EMAIL : "info@ledgercitygame.com",

  // Backend API URI
  BACKEND : "https://api.ledgercitygame.com",
  //BACKEND : "http://localhost:3000",

  // Backend API version
  VERSION : 'v1',

  // Number of months plots can be reserved for
  PLOT_RENTAL_MONTHS : 6,

  // When the next feature will be released
  RELEASE_DATE : new Date(2022, 5, 30),

  // Boolean indicating if download option is enabled
  DOWNLOAD_ENABLED : false
}
