<template>
  <b-modal id="claim_account_modal"
           ref="claim_account_modal"
           centered
           :ok-disabled="!account_specified || invalid"
           @ok="on_ok">
    <ModalWrapper>
      <div class="profile_modal_header">
        Claim Account
      </div>

      <div class="font-weight-bold">
        Account to claim:
      </div>

      <b-form-input class="mt-1" size="lg" v-model="account"
                    placeholder="XRP Account" :trim="true" />

      <div class="error" v-if="invalid">
        Invalid account
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

// TODO watch account, as soon as is is valid,
//      send 'can_associate_account' request to server.
//      If response is false, render reason on modal and disable ok

export default {
  name : 'ClaimAccount',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  components : {
    ModalWrapper
  },

  data () {
    return {
      account : null
    }
  },

  computed : {
    account_specified (){
      return !!this.account;
    },

    invalid (){
      return this.account_specified &&
             !util.is_valid_account(this.account);
    }
  },

  methods : {
    on_ok (){
      this.associate_account(this.account)
          .then(this.success.bind(this))
         .catch(this.failed.bind(this))
    },

    reset (){
      this.account = null;
    },

    success (c) {
      this.reset();
      this.$store.commit('set_last_claim', c.body)
      this.$bvModal.show('claim_verification_modal')
      this.get_user();

    },

    failed(err) {
      this.reset();

      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'account_associate_error',
        message : msg,
        is_error : true
      });
    }
  }
}
</script>

<style>
#claim_account_modal___BV_modal_header_{
  display: none;
}

#claim_account_modal___BV_modal_footer_ {
  justify-content:space-between;
}

#claim_account_modal___BV_modal_footer_ .btn-primary{
  width: 45%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

#claim_account_modal___BV_modal_footer_ .btn-secondary{
  width: 45%;
  background: var(--theme-color16);
  border: 1px solid var(--theme-color16);
  color: var(--theme-color0);
}
</style>
