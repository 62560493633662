export default {
  data : function(){
    return {
      current_index : 0
    }
  },

  computed : {
    current_item : function(){
      return this.cycle_items[this.current_index];
    }
  },

  created : function() {
    this.cycler =
      setInterval(function(){
        this.current_index += 1;
        if(this.current_index >= this.cycle_items.length)
          this.current_index = 0;
      }.bind(this), this.cycle_time)
  },

  destroyed : function() {
    clearInterval(this.cycler);
  }
}
