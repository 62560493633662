<template>
  <b-row>
    <b-col id="corporate_members">
      <h3>Members</h3>

      <b-row v-for="user in corporation.Users"
             :key="user.email">
        <b-col cols="10">
          {{user.email}}
        </b-col>

        <b-col>
          <b-button class="kick" variant="warning"
                    v-if="user.email != email"
                    @click="_kick_from_corporation(user.email)">
            Kick
          </b-button>
        </b-col>
      </b-row>

      <b-button id="invite"
                variant="primary"
                v-b-modal.invite_to_corporation_modal>
        Invite
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name : 'CorporateMembers',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  methods : {
    _kick_from_corporation (email){
      this.kick_from_corporation(this.corporation.id, email)
          .then(this.kick_from_corporation_success.bind(this))
          .catch(this.kick_from_corporation_failed.bind(this))
    },

    kick_from_corporation_success () {
      this.get_user()
    },

    kick_from_corporation_failed (err){
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'rescind_invitation_error',
        message : msg,
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
#corporate_members{
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

h3{
  text-align: center;
}

.kick{
  background: #d39e00 !important;
  color: white;
}

#invite{
  align-self: center;
  background: var(--theme-color12) !important;
  margin-top: 15px;
}
</style>
