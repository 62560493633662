export default {
  computed : {
    auth_token : function(){
      return this.$cookies.authToken;
    },

    auth_header : function(){
      return {headers : {authorization : this.auth_token}};
    },

    logged_in : function(){
      return !!this.auth_token;
    },

    ///

    handle : function(){
      return this.$store.state.user.handle;
    },
  
    email : function(){
      return this.$store.state.user.email;
    },

    privileges : function(){
      return this.$store.state.user.privileges || [];
    },
  
    account_lcc : function(){
      return this.$store.state.user.lcc;
    },

    account_destination_tag : function(){
      return this.$store.state.user.destination_tag;
    },
  
    account_disabled : function(){
      return this.$store.state.user.disabled;
    },

    account_status_text : function(){
      return this.account_disabled ? 'Disabled' : 'Active'
    },
  
    account_created : function(){
      return this.$store.state.user.createdAt;
    },

    account_created_text : function(){
      return new Date(Date.parse(this.account_created)).toLocaleString();
    },

    last_login : function(){
      return this.$store.state.user.last_login;
    },

    last_login_text : function(){
      return new Date(Date.parse(this.last_login)).toLocaleString();
    },

    has_credit_card : function(){
      return this.$store.state.user.has_credit_card;
    },

    accounts : function(){
      return this.$store.state.user.Accounts;
    },

    have_no_accounts : function(){
      return this.accounts.length == 0;
    },

    association_requests : function(){
      return this.$store.state.user.AssociationRequests;
    },

    have_no_association_requests : function(){
      return this.association_requests.length == 0;
    },

    bids(){
      return this.$store.state.user.Bids;
    },

    have_no_auction_bids(){
      return this.bids.length == 0;
    },

    corporation () {
      return this.$store.state.user.Corporation;
    },

    part_of_corporation () {
      return this.corporation != null;
    },

    owns_corporation () {
      return this.part_of_corporation &&
             this.$store.state.user.owns_corporation;
    },

    corporate_invitations () {
      return this.$store.state.user.CorporateInvitations;
    },

    invited_to_corporation () {
      return this.corporate_invitations.length > 0;
    }
  },

  methods : {
    // XXX: copied from lc-backend (models/User#privilege)
    privilege : function(type, convert, default_value){
      const privileges = this.privileges || [];
      const privilege = privileges.find(function(p){
        return p.type == type
      })

      if(!privilege){
        if(default_value)
          return default_value

        else if(convert == 'integer')
          return 0;

        else if(convert == 'boolean')
          return false;

        return null;
      }

      if(convert == 'integer')
        return parseInt(privilege.value);
      else if(convert == 'boolean')
        return ['true', 't'].includes(privilege.value.toLowerCase())

      return privilege.value;
    },
  }
}
