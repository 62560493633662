<template>
  <div id="guide">
    <h2>
      <template v-if="is_faq">
        FAQ
      </template>

      <template v-else>
        Guide
      </template>
    </h2>

    <SectionInfo>
      <template v-if="is_faq">
        Frequently Asked Questions
      </template>

      <template v-else>
        From game installation to advanced game tricks and tips, here you can find guides and how-to's.
      </template>
    </SectionInfo>

    <div id="topics">
      <b-card v-for="topic in topics"
              :key="topic.id"
              @click="nav_to(topic.link)">
        <img :src="topic.img" :id="topic.id" :alt="topic.id" />
        <div>{{topic.title}}</div>
      </b-card>
    </div>
  </div>
</template>

<script>
import SectionInfo from '@/components/SectionInfo'

export default {
  name: 'Guide',

  components : {
    SectionInfo
  },

  data () {
    return {
      is_faq : true,

      faq_topics : [
        {
          id : "what-is-ledger-city",
          title : "What is Ledger City?",
          img : require("@/assets/images/guide/getting-started.webp"),
          link : "what-is-ledger-city"
        },

        {
          id : "release-schedule",
          title : "When is it available?",
          img : require("@/assets/images/guide/claim-your-account.webp"),
          link : "release-schedule"
        },

        {
          id : "supported-wallets",
          title : "Supported Wallets",
          img : require("@/assets/images/guide/customize.webp"),
          link : "supported-wallets"
        },

        {
          id : "lcc-release",
          title : "Ledger City Coin",
          img : require("@/assets/images/guide/lcc.webp"),
          link : "lcc-release"
        },

        {
          id : "more-info",
          title : "Find out more",
          img : require("@/assets/images/guide/more.webp"),
          link : "more-info"
        },
      ],

      guide_topics : [
        {
          id : "getting-started",
          title : "Getting Started",
          img : require("@/assets/images/guide/getting-started.webp"),
          link : "getting-started"
        },

        {
          id : "claim-your-account",
          title : "Claim Your Account",
          img : require("@/assets/images/guide/claim-your-account.webp"),
          link : "claim-account"
        },

        {
          id : "customize",
          title : "Customize",
          img : require("@/assets/images/guide/customize.webp"),
          link : "customize-building"
        },

        {
          id : "navigation",
          title : "Navigation",
          img : require("@/assets/images/guide/navigation.webp"),
          link : "navigation"
        },

        {
          id : "lcc",
          title : "Ledger City Coin",
          img : require("@/assets/images/guide/lcc.webp"),
          link : "purchasing-lcc"
        },

        {
          id : "more",
          title : "More Help Topics",
          img : require("@/assets/images/guide/more.webp"),
          link : "toc"
        },
      ]
    }
  },

  computed : {
    topics () {
      return this.is_faq ?
        this.faq_topics :
        this.guide_topics;
    }
  },

  methods : {
    nav_to (link){
      if(this.is_faq)
        this.$router.push("/faq#" + link)
      else
        this.$router.push("/guide#" + link)
    }
  }
}
</script>

<style scoped>
#guide{
  margin: 25px 0;
  padding: 50px;
}

#main_layout.xs #guide{
  padding: 15px;
}

h2{
  font-weight: 900;
  font-size: 72px;
}

#main_layout.sm h2,
#main_layout.xs h2{
  font-size: 48px;
}

#topics{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 25px;
}

#topics .card{
  cursor: pointer;
  border-radius: 5px;
  min-width: 190px;
  margin: 5px;
}

#topics .card:hover{
  box-shadow: 0 0.5rem 1rem var(--theme-color28);
}

#topics .card-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}

img{
  width: 80px;
  height: 80px;
}
</style>
