import { render, staticRenderFns } from "./AssociationRequests.vue?vue&type=template&id=72a0ffda&scoped=true&"
import script from "./AssociationRequests.vue?vue&type=script&lang=js&"
export * from "./AssociationRequests.vue?vue&type=script&lang=js&"
import style0 from "./AssociationRequests.vue?vue&type=style&index=0&id=72a0ffda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a0ffda",
  null
  
)

export default component.exports