<template>
  <ul id="main_nav">
    <li><router-link to="/">GAME</router-link></li>

    <li v-if="download_enabled">
      <router-link to="/download">DOWNLOAD</router-link>
    </li>

    <li><router-link to="/gallery">GALLERY</router-link></li>
    <li><router-link to="/faq">FAQ</router-link></li>
    <li><router-link to="/blog">BLOG</router-link></li>
    <li><router-link to="/#contact">CONTACT</router-link></li>
  </ul>
</template>

<script>
import DownloadToggler from '../mixins/download_toggler'

export default {
  name: 'MainNav',

  mixins : [DownloadToggler]
}
</script>

<style scoped>
#main_nav{
  background-color: var(--theme-color0);
  display: flex;
}

#main_layout.md #main_nav,
#main_layout.sm #main_nav,
#main_layout.xs #main_nav{
  display: unset;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
}

ul{
  list-style-type: none;
  margin-bottom: 0;
}

li{
  align-self: center;
  padding: 20px;
}

li a {
  color: var(--theme-color6);
  font-size: 12px;
  letter-spacing: 3px;
}

li a:hover{
  color: var(--theme-color4);
  text-decoration: none;
}

#main_layout.md li:first-child,
#main_layout.sm li:first-child,
#main_layout.xs li:first-child{
  border-top: 0;
}

#main_layout.md li,
#main_layout.sm li,
#main_layout.xs li{
  border-top: 1px solid #EEE;
}
</style>
