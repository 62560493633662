<template>
  <b-modal id="invite_to_corporation_modal"
           ref="invite_to_corporation_modal"
           centered
           :ok-disabled="!email_specified || !specified_email_valid"
           @ok="on_ok" @shown="reset">
    <ModalWrapper>
      <div class="profile_modal_header">
        Invite to Corporation
      </div>

      <b-form-group label-for="email"
                    :invalid-feedback="specified_email_error"
                    :state="specified_email_valid">

        <b-form-input id="email" v-model="specified_email"
                      class="mt-1" size="lg"
                      placeholder="Email" :trim="true" />

      </b-form-group>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name : 'InviteToCorporation',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  components : {
    ModalWrapper
  },

  data () {
    return {
      specified_email : null
    }
  },

  computed : {
    email_specified (){
      return !!this.specified_email;
    },

    specified_email_valid (){
      return this.email_specified &&
             util.is_valid_email(this.specified_email);
    },

    specified_email_error () {
      if(this.specified_email_valid) return "Email is invalid";
      return null;
    }
  },

  methods : {
    on_ok () {
      this.invite_to_corporation(this.corporation.id, this.specified_email)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    reset (){
      this.specified_email = null;
    },

    success () {
      this.get_user();
    },

    failed (err) {
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'invite_to_corporation_error',
        message : msg,
        is_error : true
      });
    },
  }
}
</script>
