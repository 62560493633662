import zxcvbn from 'zxcvbn'
const WAValidator = require('wallet-address-validator');

import lc from './config/lc'

export default {
  capitalize (str) {
    return str[0].toUpperCase() + str.slice(1);
  },

  ///

  // XXX: validator methods copied from lc-backend util module

  // TODO: use xrpljs connection to ensure account exists on blockchain
  is_valid_account : function(test){
    return !!WAValidator.validate(test, 'XRP');
  },

  is_valid_email : function(test){
    return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(test);
  },

  // Return boolean indicating if password is weak.
  // See zxcvbn documentation for details
  is_weak_password : function(password){
    const strength = zxcvbn(password);
    return strength.score < lc.min_password_score;
  },

  // Return bool if input is valid credit card number
  is_valid_credit_card_number : function(test){
    return /^[0-9]{14}[0-9]{0,2}$/.test(test);
  },

  // Return bool if input is valid credit card cvc
  is_valid_credit_card_cvc : function(test){
    return /^[0-9]{3}$/.test(test);
  },

  format_date : function(date, opts){
    if(!opts) opts = {};

    var year  = date.getUTCFullYear();
    var month = date.getUTCMonth() + 1;
    var day   = date.getUTCDate();
    var hour  = date.getUTCHours();
    var min   = date.getUTCMinutes();
    var sec   = date.getUTCSeconds();

    if(month < 10) month = "0" + month;
    if(day   < 10)   day = "0" + day;
    if(hour  < 10)  hour = "0" + hour;
    if(min   < 10)   min = "0" + min;
    if(sec   < 10)   sec = "0" + sec;

    if(opts.no_time)
      return  year + "-" + month + "-" + day;

    return  year + "-" + month + "-" + day + " " +
          hour + ":" +   min + ":" + sec;
  }
}
