<template>
  <b-modal id="claim_verification_modal"
           ref="claim_verification_modal"
           @hidden="hidden"
           ok-only centered>
    <ModalWrapper>
      <div class="profile_modal_header">
        Claim Submitted
      </div>

      <div id="instructions">
        To verify ownership, submit two separate blockchain payments:
      </div>

      <b-container id="payment_details" :class="$mq">
        <b-row>
          <b-col>
            <span class="profile_modal_label">Source</span>:
            <span class="account">{{account}}</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span class="profile_modal_label">Destination</span>:
            <span class="account">{{destination}}</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span class="profile_modal_label">Payment 1 Amount</span>:
            {{amount1}} XRP
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span class="profile_modal_label">Payment 2 Amount</span>:
            {{amount2}} XRP
          </b-col>
        </b-row>

      </b-container>

      <hr/>

      <div>
        Both payments must be received before the claim expires at
        <div id="expires">{{expires_text}}</div>
      </div>

      <div>
        Once we receive both payments, your claim will be complete
        and we will gift you <b>{{initial_lcc}} LCC</b> to use in game.
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import lc from '@/config/lc'

export default {
  name : 'ClaimVerification',

  components : {
    ModalWrapper
  },

  computed : {
    claim () {
      return this.$store.state.last_claim;
    },

    account () {
      return this.claim.AccountId
    },

    destination () {
      return lc.wallets.purchase;
    },

    amount1 () {
      return this.claim.amount1
    },

    amount2 () {
      return this.claim.amount2
    },

    expires_text(){
      if(!this.claim.expires) return;

      return new Date(Date.parse(this.claim.expires)).toLocaleString()
    },

    initial_lcc () {
      return lc.initial_lcc;
    }
  },

  methods : {
    hidden (){
      this.$store.commit('clear_last_claim');
    }
  }
}
</script>

<style scoped>
#instructions{
  margin-bottom: 15px;
}

#expires{
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}
</style>

<style>
#claim_verification_modal #payment_details .col{
  padding: 0;
}

#claim_verification_modal #payment_details .account{
  font-size: 14px;
}

#claim_verification_modal___BV_modal_header_{
  display: none;
}

#claim_verification_modal___BV_modal_footer_ button{
  width: 100%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

</style>
