<template>
  <div id="splash">
    <div v-for="(bg,i) in cycle_items"
         :key="i" class="splash-bg"
         :style="{'background-image' : 'url(' + bg + ')', opacity : bg_opacity[i]}" />

    <div id="splash-content">
      <div id="overview-section">
        <h2>Welcome to</h2>
        <h1>Ledger City</h1>
        <p>
          Ledger City is a game powered by the XRP Blockchain. By owning crypto you own real estate. Experience true ownership of digital assets in your hands: your crypto, your property, your life!
        </p>

        <div v-if="download_enabled">
          <router-link id="download-button"
                       to="/download"
                       tag="button">
            <img src="@/assets/images/icons/Download.webp" />
            <span>Download Now</span>
          </router-link>
        </div>
      </div>

      <div v-if="!logged_in" id="login-section">
        <div id="login-header">
          <h2 class="font-weight-bolder">LOGIN</h2>

          <b-button id="register-button"
                  :disabled="controls_disabled"
                  @click="register_clicked">
            Register
          </b-button>
        </div>

        <div id="login_email_wrapper">
          <label for="login_email"
                 class="input_label">
            Email
          </label>
          <b-form-input id="login_email"
                        type="text"
                        size="lg"
                        :disabled="controls_disabled"
                        v-model="login_email"
                        v-on:keyup.enter="do_login" />

        </div>

        <div id="login_password_wrapper">
          <label for="login_password"
                 class="input_label">
            Password
          </label>
          <b-form-input id="login_password"
                        type="password"
                         size="lg"
                        :disabled="controls_disabled"
                        v-model="login_password"
                        v-on:keyup.enter="do_login" />

          <div id="forgot_password"
               class="mt-2"
               :class="{disabled : invalid_email}"
               @click="forgot_password_clicked">
            Forgot Password?
          </div>
        </div>

        <ErrorBox v-if="login_failed">
          Email or password incorrect
        </ErrorBox>

        <WarnBox v-if="maintenance_mode">
          Ledger City is undergoing maintenance, please check back later.
        </WarnBox>

        <b-button id="login-button"
                :disabled="controls_disabled"
                @click="do_login">
          Login
        </b-button>
      </div>

      <div v-else id="loggedin-section">
        <div id="loggedin-header">
          <h2>Welcome</h2>
        </div>

        <div id="loggedin-avatar">
          <img src="@/assets/images/icons/avatar.webp" alt="avatar" />
        </div>

        <div id="loggedin-handle">
          {{handle}}
        </div>

        <button id="profile_button" @click="profile_clicked">
          My Profile
        </button>

        <button id="logout_button" @click="logout_clicked">
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Cycler          from '@/mixins/Cycler'
import Authentication  from '@/mixins/authentication'
import ServerAPI       from '@/mixins/server_api'
import MaintenanceMode from '@/mixins/maintenance_mode'
import DownloadToggler from '@/mixins/download_toggler'
import TriggersModal   from '@/mixins/triggers_modal'

import ErrorBox from '@/components/ErrorBox'
import WarnBox from '@/components/WarnBox'

import util from '@/util'

export default {
  name: 'Login',

  mixins : [
    Cycler,
    Authentication,
    ServerAPI,
    MaintenanceMode,
    DownloadToggler,
    TriggersModal
  ],

  components : {
    ErrorBox,
    WarnBox
  },

  data : function(){
    return {
      login_email : '',
      login_password : '',

      cycle_time : 5000,
      cycle_items : [
        require('@/assets/images/landing/splash1.webp'),
        require('@/assets/images/landing/splash2.webp'),
        require('@/assets/images/landing/splash3.webp'),
        require('@/assets/images/landing/splash4.webp'),
        require('@/assets/images/landing/splash5.webp'),
        require('@/assets/images/landing/splash6.webp')
      ]
    }
  },

  computed : {
    bg_opacity : function() {
      return Array.from(this.cycle_items, function(_, i){
        return i == this.current_index ? 1 : 0;
      }.bind(this))
    },

    login_failed : function(){
      return this.$store.state.global_result.id == 'login_error';
    },

    invalid_email : function(){
      return !util.is_valid_email(this.login_email);
    },

    controls_disabled : function(){
      return this.maintenance_mode;
    }
  },

  methods : {
    do_login(){
      this.login(this.login_email, this.login_password)
          .then(this.successful_login.bind(this))
          .catch(this.failed_login.bind(this));
    },

    successful_login(response) {
      this.$store.commit('clear_global_result');
      this.$setCookie("authToken", response.body.authToken);
      this.get_user();
    },

    failed_login(err) {
      const msg = util.capitalize(err.body.error)

      this.$store.commit('set_global_result', {
        id : 'login_error',
        message : 'Could not login: ' + msg,
        is_error : true
      })
    },

    register_clicked(){
      this.$store.commit('set_registration', {
        email : this.login_email,
        password : this.login_password
      });
      this.$router.push('/register')
    },

    profile_clicked(){
      this.$router.push('/profile')
    },

    logout_clicked(){
      this.logout();
    },

    forgot_password_clicked(){
      if(this.invalid_email) return;

      this.reset_password_confirm(this.login_email)
          .then(this.success_password_reset.bind(this))
          .catch(this.failed_password_reset.bind(this));
    },

    success_password_reset () {
      this.show_modal({
        id : 'reset_password',
        message : 'Please check your email to reset password',
        is_error : false
      })
    },

    failed_password_reset (){
      this.show_modal({
        id : 'reset_password_error',
        message : 'Could not reset password',
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
#splash{
  position: relative;
  height: 690px;
}

#main_layout.xl #splash,
#main_layout.lg #splash,
#main_layout.md #splash{
  position: relative;
  height: 640px;
}

#main_layout.sm #splash,
#main_layout.xs #splash{
  height: unset;
  margin-bottom: 50px;
}

.splash-bg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 690px;
  transition: opacity .7s ease;
  background-size: 100% 100%;
}

.splash-bg:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--theme-color8);
}

#main_layout.xl .splash-bg,
#main_layout.lg .splash-bg,
#main_layout.md .splash-bg{
  height: 640px;
}

#main_layout.sm .splash-bg,
#main_layout.xs .splash-bg{
  height: 390px;
}

#splash-content{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  color: var(--theme-color0);
}

#main_layout.sm #splash-content,
#main_layout.xs #splash-content{
  flex-direction: column;
  padding-top: 25px;
}

#overview-section{
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#main_layout.sm #overview-section,
#main_layout.xs #overview-section{
  flex-basis: unset;
  height: 365px;
  padding: 0 25px;
}

#overview-section h1{
  font-weight: 900;
  font-size: 96px;
  line-height: 100%
}

#main_layout.sm #overview-section h1{
  font-size: 76px;
}

#main_layout.xs #overview-section h1{
  font-size: 46px;
}

#overview-section h2{
  font-weight: bold;
  font-size: 32px;
  line-height: 24px;
}

#overview-section p{
  font-weight: 500;
  font-size: 20px;
  line-height: 146%;
}

#main_layout.sm #overview-section p,
#main_layout.xs #overview-section p{
  font-size: 18px;
}

#download-button{
  background-color: var(--theme-color0);
  color: var(--theme-color9);
  border: 2px solid white;
  border-radius: 16px;
  min-width: 250px;
  padding: 5px 15px;
  margin: 0 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 800;
  font-size: 22px;
  line-height: 25px;
}

#login-section,
#loggedin-section{
  flex-basis: 32%;
  padding: 20px 28px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: var(--theme-color0);
  color: var(--theme-color10);
}

#main_layout.lg #login-section,
#main_layout.md #login-section,
#main_layout.lg #loggedin-section,
#main_layout.md #loggedin-section{
  flex-basis: 46%;
}

#main_layout.sm #login-section,
#main_layout.xs #login-section,
#main_layout.sm #loggedin-section,
#main_layout.xs #loggedin-section{
  flex-basis: unset;
  width: 100%;
  width: 100%;
}

#login-section{
  min-height: 425px;
}

#loggedin-section{
  min-height: 500px;
}

#loggedin-section{
  align-items: center;
}

#login-header{
  display: flex;
  justify-content: space-between;
}

#register-button{
  background: var(--theme-color0);
  border: 1px solid var(--theme-color9);
  border-radius: 40px;
  min-width: 130px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color9);
}

#main_layout.md #register-button,
#main_layout.sm #register-button,
#main_layout.xs #register-button{
  min-width: 100px;
}

#login-section .input_label{
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 19.5px;
  color: var(--theme-color11);
  margin-bottom: 10px;
}

#login-section input{
  width: 100%;
}

#forgot_password{
  cursor: pointer;
  text-align: right;
  font-size: 14px;
  color: var(--theme-color4);
}

#forgot_password.disabled{
  cursor: default;
  color: var(--theme-color10);
}

#login-button{
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  min-height: 60px;
  background: linear-gradient(90.7deg, var(--theme-color12) 6.13%, var(--theme-color9) 82.28%);
  box-shadow: 0px 24px 56px var(--theme-color13);
  border-radius: 16px;
  transition: 0.3s;
}

#login-button:hover {
  opacity: 0.8;
  box-shadow: 0px 24px 56px var(--theme-color24);
}

#loggedin-header h2{
  font-weight: 900;
  font-size: 32px;
}

#loggedin-handle {
  color: var(--theme-color9);
  font-weight: 900;
  font-size: 24px;
}

#profile_button{
  background: var(--theme-color9);
  color: var(--theme-color0);
  border-radius: 16px;
  font-weight: 800;
  font-size: 24px;
  padding: 15px 100px;
  margin: 15px;
}

#logout_button{
  background: var(--theme-color0);
  border: 1px solid var(--theme-color16);
  border-radius: 40px;
  min-width: 130px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: var(--theme-color16);
  padding: 15px;
}
</style>
