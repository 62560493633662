<template>
  <div id="social" class="footer_section">
    <a :href="email">
      <img src="@/assets/images/icons/email.webp" alt="email" />
      <i class="fab fa-google" />
    </a>

    <a href="https://twitter.com/LedgerCityGame">
      <img src="@/assets/images/icons/twitter.webp" alt="twitter" />
    </a>
  </div>
</template>

<script>
import config from '@/config/config'

export default {
  name: 'Contact',

  computed : {
    email : function(){
      return 'mailto:' + config.EMAIL;
    }
  }
}
</script>

<style scoped>
#social{
  flex-basis: 20%;
  display: flex;
  justify-content: flex-start;
}

#main_layout.sm #social,
#main_layout.xs #social{
  justify-content: space-evenly;
}

a{
  margin-right: 15px;
}

#main_layout.md a,
#main_layout.sm a,
#main_layout.xs a{
  margin-right: unset;
}
</style>
