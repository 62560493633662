<template>
  <b-toast id="cookies-policy"
           toaster='b-toaster-bottom-right'
           no-auto-hide
           no-close-button>
    <p>
    <b>Our website uses cookies.</b>
    </p>

    <p>
    By using our site you agree to the use of cookies.
    </p>

    <b-button @click="hide" variant="primary">
      Agree
    </b-button>
  </b-toast>
</template>

<script>
// Set to always remove cookie on instantiation
const FORCE_CLEAR = false;

export default{
  name: 'CookiesPolicy',

  computed : {
    accepted : {
      get() {
        return this.$cookies.cookiesAccepted;
      },

      set(v) {
        this.$setCookie("cookiesAccepted", v);
      }
    }
  },

  methods : {
    hide () {
      this.$bvToast.hide('cookies-policy');
      this.accepted = true;
    }
  },

  mounted(){
    if(this.accepted){
      if(FORCE_CLEAR)
        this.$removeCookie("cookiesAccepted");
      else
        return;
    }

    this.$bvToast.show('cookies-policy');
  }
}
</script>

<style scoped>
#cookies-policy button{
  margin-left: 75%;
}
</style>
