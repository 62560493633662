<template>
  <b-container id="corporations_tab">
    <Corporation />
    <CorporateMembers v-if="owns_corporation" />
    <CorporateInvitations />
  </b-container>
</template>

<script>
import Authentication from '@/mixins/authentication'

import Corporation from './corporations/Corporation'
import CorporateMembers from './corporations/CorporateMembers'
import CorporateInvitations from './corporations/CorporateInvitations'


export default {
  name : 'CorporationsTab',

  components : {
    Corporation,
    CorporateMembers,
    CorporateInvitations
  },

  mixins : [Authentication]
}
</script>
