<template>
  <b-modal id="withdraw_lcc_modal"
           ref="withdraw_lcc_modal"
           :ok-disabled="!valid"
           @ok="on_ok"
           centered>
    <ModalWrapper>
      <div class="profile_modal_header">
        Withdraw LCC
      </div>

      <p>
        Use this form to <b>withdraw LCC</b> to the XRP Blockchain.
      </p>

      <WarnBox>
        Once LCC is withdrawn it cannot be used in Ledger City until it is redeemed.
      </WarnBox>

      <div class="header">Destination Account:</div>

      <b-form-input size="lg" v-model="account"
                    placeholder="XRP Account" />

      <div class="error" v-if="invalid_account">
        Invalid account
      </div>

      <div class="header">Destination Tag (optional):</div>

      <b-form-input size="lg" v-model="destination_tag"
                    placeholder="Destination Tag" />

      <div class="header">LCC:</div>

      <b-form-input type="range"
                     v-model="lcc"
                     :number="true"
                     min="1"
                     :max="account_lcc" />

      <div id="lcc" :class="{warn : more_than_50p,
                           danger : more_than_75p}">
        {{lcc.toLocaleString()}} LCC
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'
import WarnBox from '@/components/WarnBox'

import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'
import util from '@/util'

// TODO watch account, as soon as it is valid,
//      send 'can_withdraw_lcc' request to server.
//      If response is false, render reason on modal & disable
//      ok until account is changed.

export default {
  name : 'WithdrawLCC',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  components : {
    ModalWrapper,
    WarnBox
  },

  data () {
    return {
      lcc : 1,
      account : null,
      destination_tag : null
    }
  },

  computed :{
    more_than_50p(){
      return this.lcc / this.account_lcc > 0.5;
    },

    more_than_75p(){
      return this.lcc / this.account_lcc > 0.75;
    },

    account_specified (){
      return !!this.account;
    },

    invalid_account (){
      return this.account_specified &&
             !util.is_valid_account(this.account)
    },

    valid_account() {
      return this.account_specified &&
             util.is_valid_account(this.account)
    },

    valid (){
      return this.account_specified && this.valid_account;
    }
  },

  methods : {
    on_ok (){
      this.withdraw_lcc(this.lcc, this.account, this.destination_tag)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    reset () {
      this.lcc = 1;
      this.account = null;
      this.destination_tag = null;
    },

    success () {
      this.reset();
      this.show_modal({
        id : 'lcc_withdrawl',
        message : 'Check your email to complete your LCC Withdrawl request',
        is_error : false
      })
    },

    failed (err) {
      this.reset();
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'lcc_withdrawl_error',
        message : 'Could not create LCC withdrawl request: ' + msg,
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
.header{
  margin-top: 20px;
  font-weight: bold;
}

input{
  margin-top: 8px;
  width: 100%;
}

#lcc{
  text-align: center;
  font-weight: bold;
}

#lcc.warn{
  color: orange;
}

#lcc.danger{
  color: red;
}
</style>

<style>
#withdraw_lcc_modal___BV_modal_header_{
  display: none;
}

#withdraw_lcc_modal___BV_modal_footer_ {
  justify-content: space-between;
}

#withdraw_lcc_modal___BV_modal_footer_ .btn-primary{
  width: 45%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

#withdraw_lcc_modal___BV_modal_footer_ .btn-secondary{
  width: 45%;
  background: var(--theme-color16);
  border: 1px solid var(--theme-color16);
  color: var(--theme-color0);
}
</style>
