<template>
  <div id="subscribe">
    <form id="sib-form" method="POST" action="https://9cb309cc.sibforms.com/serve/MUIEAGuRFYdWDHguGE4J8a3Kcd49v4T-DAidbLKKVG_mgxwpPYcqIRdRK9n9Q4siFdsF9QFte3Ryx5JioDKzCVsXHxy9IUQBxttkHo-FjveONiy2EbouYdFnuqSBZUqDq6oWZt7bWb8FGQhc5n5hpWWGkDR8nwnEExUKVDeuNbmZ_ypKbW8zoy6oA-YRdHNmIFFx5DxiiSNZKeYB">
      <input class="input " type="text" id="EMAIL" name="EMAIL" autocomplete="off" placeholder="Subscribe to the mailing list" data-required="true" required />
      <button class="sib-form-block__button sib-form-block__button-with-loader" form="sib-form" type="submit">
        SUBSCRIBE
      </button>
      <div style="display: none">
        <input type="text" name="email_address_check" value="" class="input--hidden"/>
        <input type="hidden" name="locale" value="en"/>
        <input type="hidden" name="html_type" value="simple"/>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'Subscribe'
}
</script>

<style scoped>
#subscribe {
  flex-basis: 40%;
  border: 1px solid white;
  border-radius: 16px;
  margin: 10px 0;
  padding: 3px;
  min-width: 300px;
}

#main_layout.xl #subscribe {
  padding: 8px;
}

#subscribe form{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#main_layout.xs #subscribe form{
  padding: 3px;
}

input{
  background: none;
  color: var(--theme-color0);
  border: none;
  flex-basis: 65%;
  height: 48px;
  font-size: 0.95rem;
}

input:focus{
  outline: none;
}

input::placeholder{
  color: var(--theme-color29) ;
}
button{
  color: var(--theme-color9);
  background: var(--theme-color0);
  box-shadow: 0px 24px 56px var(--theme-color13);
  border-radius: 8px;
  border: none;
  font-weight: bold;
  font-size: 12px;
  flex-basis: 25%;
  height: 48px;
  border-radius: 12px;
  transition: 0.3s;
}
button:hover {
  opacity: 0.7;
}
</style>
