<template>
  <div class="modal_wrapper" :class="$mq">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ModalWrapper'
}
</script>
