<template>
  <div id="copyright">
    <div>
      Copyright (C) Dev Null Productions 2021-2022
    </div>

    <div>
      All Rights Reserved
    </div>

    <div>
      <router-link to="/terms">Terms Of Use</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
}
</script>

<style scoped>
#copyright{
  flex-basis: 70%;
  display: flex;
  justify-content: flex-end;
  color: var(--theme-color0);
  opacity: 0.5;
  font-size: 0.875rem;
  margin: 8px 0;
  align-items: center;
}

#main_layout.sm #copyright,
#main_layout.xs #copyright{
  margin-top: 5px;
  flex-direction: column;
}

#copyright div::after{
  content: "|";
  margin: 0 16px;
}

#copyright div:last-of-type::after{
  content: unset;
}

#main_layout.md #copyright div::after,
#main_layout.sm #copyright div::after,
#main_layout.xs #copyright div::after{
  content : unset;
}

a {
  color: var(--theme-color0);
}

a:hover {
  text-decoration: none;
}
</style>
