<template>
  <div id="main_header">
    <div id="hamburger_logo">
      <div v-if="mq_lte_md" id="hamburger" @click="toggle_nav = !toggle_nav">
        ☰
      </div>

      <div id="logo_wrapper">
        <router-link to="/">
          <img id="logo" src="@/assets/images/icons/logo.webp" alt="Logo" />
        </router-link>
      </div>
    </div>

    <MainNav v-if="mq_gt_md || toggle_nav"/>
  </div>
</template>

<script>
import MainNav from './MainNav'

export default {
  name: 'MainHeader',

  components : {
    MainNav
  },

  data : function(){
    return {
      toggle_nav : false
    }
  }
}
</script>

<style scoped>
#main_header{
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 199;

  background-color: var(--theme-color0);
  border-bottom: 1px solid var(--theme-color26);
  border-bottom-color: var(--theme-color1);
}

#main_layout.md #main_header,
#main_layout.sm #main_header,
#main_layout.xs #main_header{
  display: unset;
  background-color: var(--theme-color0);
}


#hamburger_logo{
  display: flex;
  justify-content: center;
  align-items: center;
}

#hamburger{
  cursor: pointer;
}

#logo_wrapper{
  flex-basis: calc(100% - 15px);
  text-align: center;
}

#logo{
  height: 70px;
}
</style>
