<template>
  <b-row>
    <b-col>
      <b-row class="no_property_records" v-if="have_no_accounts">
        <b-col>
          <i>You currently do not have any property.</i>
        </b-col>
      </b-row>

      <div class="property_records" v-else>
        <b-row class="property_header">
          <b-col>
            Owned Accounts:
          </b-col>
        </b-row>

        <template v-if="mq_gt_xs">
          <b-row class="property_column_header">
            <b-col>
              Address
            </b-col>

            <b-col cols="4" class="property_column_header">
              Balance
            </b-col>
          </b-row>
    
          <b-row v-for="account in accounts"
                  :key="account.id"
                  class="account_row">
            <b-col>
              {{account.id.substr(0, mq_lte_md ? 13 : 20)}}...
            </b-col>
    
            <b-col cols="4">
              {{account.balance}} XRP
            </b-col>
          </b-row>
        </template>

        <template v-else>
          <b-row v-for="account in accounts"
                  :key="account.id"
                  class="account_row">
            <b-col>
              <b-row>
                <b-col class="property_column_header">
                  Address
                </b-col>

                <b-col>
                  {{account.id.substr(0, 13)}}...
                </b-col>
              </b-row>

              <b-row>
                <b-col class="property_column_header">
                  Balance
                </b-col>

                <b-col>
                  {{account.balance}} XRP
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'

export default {
  name: 'Accounts',
  mixins : [Authentication]
}
</script>

<style scoped>
#main_layout.xs .account_row{
  padding-bottom: 15px;
  border-bottom: 1px solid var(--theme-color10);
}

#main_layout.xs .account_row:last-of-type{
  border-bottom: unset;
}
</style>
