<template>
  <b-row no-gutters>
    <b-col>
      <div class="header">Email</div>

      <div class="content" v-if="!edit">
        {{email}}
      </div>

      <div class="input mt-1" v-else>
        <b-form-input size="lg" v-model="specified_email"
                      :placeholder="email" />
      </div>

      <div class="error_placeholder" v-if="!mq_xs && no_errors"/>

      <div class="error" v-if="invalid">
        Invalid
      </div>
    </b-col>

    <b-col cols="2" v-if="edit"
           id="update_col" class="button_col">
      <b-button class="update"
            :disabled="update_disabled"
            @click="update">
        Update
      </b-button>
    </b-col>

    <b-col cols="2"
           id="edit_col" class="button_col">
      <b-button :class="{edit : !edit,
                       cancel :  edit}"
                @click="toggle_edit">
        <template v-if="!edit">
          Edit
          <img src="@/assets/images/icons/edit.webp" alt="edit" />
        </template>

        <template v-else>
          Cancel
        </template>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name: 'Email',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  data () {
    return {
      edit : false,
      specified_email : null
    }
  },

  computed : {
    email_is_specified () {
      return !!this.specified_email;
    },

    specified_same_email () {
      return this.specified_email == this.email;
    },

    invalid () {
      return this.email_is_specified &&
             !util.is_valid_email(this.specified_email)
    },

    any_errors () {
      return this.invalid;
    },

    no_errors () {
      return !this.any_errors;
    },

    update_disabled (){
      return !this.email_is_specified ||
              this.specified_same_email ||
              this.any_errors;
    }
  },

  methods : {
    toggle_edit(){
      this.edit = !this.edit;
      this.specified_email = null;
      this.$store.commit('clear_global_result');
    },

    update(){
      this.update_user({email : this.specified_email})
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    success(){
      this.toggle_edit();
      this.get_user();

      this.show_modal({
        id : 'email_updated',
        message : 'Please check your email to confirm update.'
      })
    },

    failed(err){
      this.toggle_edit();

      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'update_email_error',
        message : 'Could not update user: ' + msg,
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
#main_layout.xs .row{
  flex-direction: column;
}

#main_layout.xs #update_col,
#main_layout.xs #edit_col {
  align-self: flex-end;
  margin-top: 5px;
}

.input{
  width: 50%;
}

#main_layout.xs .input{
  width: unset;
}
</style>
