<template>
  <b-container fluid
               id="main_layout"
               class="p-0"
               :class="$mq">
    <CookiesPolicy />
    <ResultModal />
    <MainHeader v-if="!no_header" />

    <slot></slot>

    <MainFooter v-if="!no_footer" />
  </b-container>
</template>

<script>
import Authentication from '../mixins/authentication'
import ServerAPI      from '../mixins/server_api'

import CookiesPolicy from './CookiesPolicy'
import ResultModal   from './ResultModal'
import MainHeader    from './MainHeader'
import MainFooter    from './MainFooter'

// TODO: Periodically get_user, if not authenticated, logout and redirect to landing

export default {
  name: 'MainLayout',

  mixins : [
    Authentication,
    ServerAPI
  ],

  components: {
    CookiesPolicy,
    ResultModal,
    MainHeader,
    MainFooter
  },

  props : {
    section : {
      type : String,
      required : true
    },

    no_header : {
      type : Boolean,
      default : false
    },

    no_footer : {
      type : Boolean,
      default : false
    }
  },

  computed : {
    initialized : {
      get(){
        return this.$store.state.initialized;
      },

      set(v){
        this.$store.commit('set_initialized', v);
      }
    }
  },

  created : function(){
    if(!this.initialized){
      this.initialized = true;

      // If logged in, always load user
      if(this.logged_in)
        this.get_user();
    }
  }
}
</script>

<style scoped>
#main_layout{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.page-title {
  font-size: 6rem;
  margin-top: 2rem
}
#main_layout.md  .page-title,
#main_layout.sm  .page-title,
#main_layout.xs  .page-title {
  font-size: 3rem;
  margin-top: 2rem;
}

.page-subtitle {
  font-size: 1.25rem;
  color: var(--theme-color14);
  line-height: 1.5rem;
  margin: 2rem 0;
  padding-left: 1rem;
  border-left: 4px solid var(--theme-color9);
}

#main_layout.sm  .page-subtitle,
#main_layout.xs  .page-subtitle {
  font-size: 0.875rem;
  margin: 1rem 0;
}
</style>
