// Provides helpers to trigger global result modal

export default {
  methods : {
    // Invoke callback when modal is closed
    on_modal_hide(cb){
      const hide_cb = function() {
        this.$root.$off('bv::modal::hide', hide_cb);
        cb();
      }.bind(this);

      this.$root.$on('bv::modal::hide', hide_cb);
    },

    // Set global result and show modal
    show_modal (opts){
      this.$store.commit('set_global_result', opts);
      this.$bvModal.show('result_modal');
    }
  }
}
