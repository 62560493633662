<template>
  <div id="contact">
    <h2>Contact Us</h2>
      <b-container class="mt-4">
        <div class="card">
          <div class="card-body px-4">
            <b-form :action="action" method="POST">
              <b-row class="my-3">
                <b-col>
                  <b-form-input size="lg" name="name" placeholder="Name" />
                </b-col>

                <b-col>
                  <b-form-input size="lg" type="email" name="email" placeholder="Email" />
                </b-col>
              </b-row>

              <b-row class="my-3">
                <b-col>
                  <b-form-textarea size="lg" name="message" placeholder="Message" rows="10" />
                </b-col>
              </b-row>
            

              <input type="hidden" name="_next" :value="redirect_to" />
              <input type="hidden" name="_subject" value="Ledger City Inquiry" />
              <input type="hidden" name="_autoresponse" value="Thank you for contacting Ledger City. We have received your inquiry and will respond soon!" />

              <div id="send-message-container">
                <b-button type="submit" id="send-message">Send Message</b-button>
              </div>
            </b-form>
          </div>
        </div>
      
    </b-container>
  </div>
</template>

<script>
import config from '../../config/config'

export default {
  name: 'Contact',

  computed : {
    action(){
      return "https://formsubmit.co/83a25633e97318124593a2c374b6e26a"
      //return "https://formsubmit.co/info@ledgercitygame.com"
    },

    redirect_to(){
      return config.URL + "/#contact"
    }
  }
}
</script>

<style scoped>
#contact{
  padding: 75px;
}

#main_layout.sm #contact{
  padding: 50px 0;
}

#main_layout.xs #contact {
  padding: 15px 0;
}

#contact h2{
  text-align: center;
  font-size: 72px;
  font-weight: 900;
  color: var(--theme-color5);
}
#contact .card {
  border-radius: 8px;
}
#main_layout.xs h2 {
  font-size: 48px;
}

#main_layout.sm .container,
#main_layout.xs .container {
  padding: 0;
}

#send-message-container{
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  margin-bottom: 32px;
}

/* #send-message{
  background-color: transparent;
  color: var(--theme-color6);
  border: 2px solid var(--theme-color6);
  border-radius: 20px;
  padding: 5px 20px;
  letter-spacing: 2px;
  cursor: pointer;
} */

#send-message{
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  min-height: 60px;
  background: linear-gradient(90.7deg, var(--theme-color12) 6.13%, var(--theme-color9) 82.28%);
  box-shadow: 0px 24px 56px var(--theme-color13);
  border-radius: 16px;
  transition: 0.3s;
  min-width: 240px;
  cursor: pointer;
}

#send-message:hover {
  opacity: 0.8;
  box-shadow: 0px 24px 56px var(--theme-color24);
}

#send-message:hover{
  background-color: var(--theme-color6);
  color: var(--theme-color0);
}
</style>
