<template>
  <b-row>
    <b-col id="corporate_invitations" v-if="has_invitation">
      <h3>{{owns_corporation ? 'Invited:' : 'Invitations:'}}</h3>

      <template v-if="owns_corporation || invited_to_corporation">
        <b-row v-for="invitation in invitations"
               :key="invitation.id">
          <b-col cols="10">
            {{owns_corporation ? invitation.User.email : invitation.Corporation.title}}
          </b-col>

          <b-col v-if="owns_corporation">
            <b-button class="rescind" variant="warning"
                      @click="rescind_invitation(invitation.User.email)">
              Rescind
            </b-button>
          </b-col>

          <b-col v-else-if="!part_of_corporation">
            <b-button class="join"
                     @click="_join_corporation(invitation.CorporationId)">
              Join
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name : 'CorporateInvitations',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  computed : {
    invitations () {
      return this.owns_corporation ?
             this.corporation.CorporateInvitations :
             this.corporate_invitations;
    },

    has_invitation () {
      return this.invitations.length > 0;
    }
  },

  methods : {
    _join_corporation (id) {
      this.join_corporation(id)
          .then(this.join_corporation_success.bind(this))
          .catch(this.join_corporation_failed.bind(this))
    },

    join_corporation_success () {
      this.get_user();
    },

    join_corporation_failed (err) {
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'join_corporation_error',
        message : msg,
        is_error : true
      });
    },

    rescind_invitation (email){
      this.kick_from_corporation(this.corporation.id, email)
          .then(this.rescind_invitation_success.bind(this))
          .catch(this.rescind_invitation_failed.bind(this))
    },

    rescind_invitation_success () {
      this.get_user()
    },

    rescind_invitation_failed (err){
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'rescind_invitation_error',
        message : msg,
        is_error : true
      });
    }
  }
}
</script>

<style scoped>
#corporate_invitations{
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

h3{
  text-align: center;
}

.join{
  background: var(--theme-color12) !important;
  color: white;
}

.rescind{
  background: #d39e00 !important;
  color: white;
}
</style>
