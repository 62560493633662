import public_config from '@/config/public'

export default {
  computed : {
    maintenance_mode : function(){
      return public_config.maintenance_mode;
    },
  },

  methods : {
    // Requires component to have 'triggers_modal' mixin
    show_maintenance_dialog : function(){
      // Navigate to root when modal hidden
      this.on_modal_hide(function(){
        this.$router.push("/");
      }.bind(this))

      this.show_modal({
        id : 'maintenance_mode',
        is_warning : true,
        message : 'Ledger City is undergoing maintenance, please check back later'
      })
    }
  }
}
