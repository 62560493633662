//import buildings from '../assets/data/buildings.json'
//import neighborhoods from '../assets/data/neighborhoods.json'

export default { 
  computed : {
    buildings () {
      //return buildings.map(b => {
      //  let nb = Object.assign({}, b);
      //  nb.neighborhood = neighborhoods.find(n => n.id == b.neighborhood)
      //  return nb;
      //});
    },

    auctioned_buildings () {
      //return this.buildings.filter(b => b.auction)
    }
  }
}
