<template>
  <b-modal id="purchase_lcc_modal"
           ref="purchase_lcc_modal"
           :ok-disabled="!valid"
           :ok-only="xrp_active"
           :ok-title="xrp_active ? 'OK' : 'Purchase'"
           @ok="on_ok"
           @shown="visible = true"
           @hidden="visible = false"
           centered>
    <ModalWrapper>
      <div class="profile_modal_header">
        Purchase LCC
      </div>

      <div id="header" >
        <b-button :class="{active : credit_card_active}"
                  @click="set_active_tab('credit_card')">
          Credit Card
        </b-button>

        <b-button :class="{active : xrp_active}"
                  @click="set_active_tab('xrp')">
          XRP
        </b-button>
      </div>

      <ByCreditCard v-show="credit_card_active"
                    ref="by_credit_card" />
      <ByXRP        v-show="xrp_active"
                    ref="by_xrp" />
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import ByCreditCard from './PurchaseLCC/ByCreditCard'
import ByXRP from './PurchaseLCC/ByXRP'

export default {
  name : 'PurchaseLCC',

  components : {
    ModalWrapper,
    ByCreditCard,
    ByXRP
  },

  data () {
    return {
      visible : false,
      active_tab : 'credit_card'
    }
  },

  computed : {
    credit_card_active () {
      return this.active_tab == 'credit_card';
    },

    xrp_active () {
      return this.active_tab == 'xrp';
    },

    valid () {
      if(!this.visible) return false;

      if(this.credit_card_active)
        return this.$refs.by_credit_card.valid;

      return this.xrp_active;
    }
  },

  methods : {
    set_active_tab (tab){
      this.active_tab = tab;
    },

    on_ok () {
      if(this.xrp_active) return;
      this.$refs.by_credit_card.on_ok();
    }
  }
}
</script>

<style scoped>
#header{
  background: var(--theme-color21);
  padding: 15px;
  border-radius: 16px;
}

#header button{
  width: 50%;
  background: none;
  color: var(--theme-color10);
  border: none;
  padding: 15px;
  font-size: 24px;
  border-radius: 8px;
}

.modal_wrapper.xs #header button,
.modal_wrapper.sm #header button {
  padding: 12px;
  font-size: 16px;
}

#header button.active{
  background: var(--theme-color0);
  color: var(--theme-color9);
  box-shadow: 0px 16px 38px var(--theme-color27);
  border-radius: 8px;
  font-weight: bold;
}
</style>

<style>
#purchase_lcc_modal___BV_modal_header_{
  display: none;
}

#purchase_lcc_modal___BV_modal_footer_ {
  justify-content: space-between;
}

#purchase_lcc_modal___BV_modal_footer_ .btn-primary{
  width: 45%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

#purchase_lcc_modal___BV_modal_footer_ .btn-secondary{
  width: 45%;
  background: var(--theme-color16);
  border: 1px solid var(--theme-color16);
  color: var(--theme-color0);
}
</style>
