<template>
  <b-row id="timestamps" no-gutters>
    <b-col>
      Created: {{account_created_text}}
    </b-col>

    <b-col>
      Last Login: {{last_login_text}}
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'

export default {
  name: 'Status',

  mixins : [Authentication]
}
</script>

<style scoped>
#timestamps{
  margin-top: 50px;
  padding: 15px;
  background: var(--theme-color21);
  border-radius: 16px;
  text-align: center;
}

#timestamps .col:last-of-type {
  border-left: 1px solid var(--theme-color10);
}
</style>
