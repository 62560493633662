<template>
  <b-row id="status" no-gutters>
    <b-col>
      <div class="header">Status</div>

      <div class="content" :class="{disabled : account_disabled}">
        {{account_status_text}}
      </div>
    </b-col>

    <b-col cols="2" class="button_col">
      <b-button class="cancel"
               @click="logout_">
        Logout
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'

export default {
  name: 'Status',

  mixins : [Authentication, ServerAPI],

  methods : {
    logout_ () {
      this.logout();
      this.$router.push("/")
    }
  }
}
</script>

<style scoped>
#status .content{
  color: var(--theme-color18);
}

#status .content.disabled{
  color: var(--theme-color16);
}
</style>
