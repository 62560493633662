<template>
  <b-modal id="leave_corporation_modal"
           ref="leave_corporation_modal"
           centered
           @ok="on_ok">
    <ModalWrapper>
      <div class="profile_modal_header">
        Are you sure?
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'

export default {
  name : 'LeaveCorporation',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  components : {
    ModalWrapper
  },

  methods : {
    on_ok () {
      this.leave_corporation(this.title)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    success () {
      this.get_user();
    },

    failed (err) {
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'leave_corporation_error',
        message : msg,
        is_error : true
      });
    },
  }
}
</script>
