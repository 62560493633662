<template>
  <div>
    <div v-if="has_credit_card">
      <b-form-checkbox v-model="use_existing_credit_card">
        Use Existing
      </b-form-checkbox>
    </div>

    <template v-if="!has_credit_card || !use_existing_credit_card">
      <div id="credit_card_number_wrapper">
        <div class="header">
          Card Number:
        </div>

        <input id="credit_card_number"
              class="form-control"
               type="text"
               maxlength="19"
               size="19"
               :value="credit_card_number_with_dashes"
               @input="set_credit_card_number($event.target.value)" />

        <div class="error" v-if="invalid_credit_card_number">
           Must be 14 to 16 digits
        </div>
      </div>

      <!-- -->

      <div id="credit_card_cvc_wrapper">
        <div class="header">
          Security Code:
        </div>

        <input id="credit_card_cvc"
               class="form-control"
               type="text"
               maxlength="3"
               size="3"
               :value="credit_card_cvc"
               @input="set_credit_card_cvc($event.target.value)" />

        <div class="error" v-if="invalid_credit_card_cvc">
           Must be 3 digits
        </div>
      </div>

      <!-- -->

      <div class="header">
        Expiration Date:
      </div>

      <div id="credit_card_expiration_wrapper">
        <b-form-select id="credit_card_month"
                       class="mr-2"
                       v-model="credit_card_month"
                       :options="credit_card_months" />

        <b-form-select id="credit_card_year"
                       class="ml-2"
                       v-model="credit_card_year"
                       :options="credit_card_years" />
      </div>
    </template>

    <div class="header">
      <b>LCC</b> to purchase:
    </div>

    <b-form-input type="number" :min="0" v-model="lcc" />

    <hr/>

    <p id="output">
      You are purchasing <b>${{credit_card_total_price.toFixed(2)}}</b> worth of LCC
    </p>
  </div>
</template>

<script>
import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import HasCreditCard from '@/mixins/has_credit_card'
import TriggersModal from '@/mixins/triggers_modal'

import util from '@/util'
import lc from '@/config/lc'

export default {
  name : 'ByCreditCard',

  mixins : [
    Authentication,
    ServerAPI,
    HasCreditCard,
    TriggersModal
  ],

  data (){
    return {
      use_existing_credit_card : true,
      lcc : 0
    }
  },

  computed : {
    have_lcc_amount(){
      return this.lcc > 0;
    },

    lcc_per_xrp () {
      return this.$store.state.meta.xchg_rate_xrpusd / lc.lcc_price;
    },

    credit_card_total_price () {
      if(!this.lcc) return 0;

      return parseInt(this.lcc) * lc.lcc_price;
    },

    valid () {
      return this.have_lcc_amount &&
            ((this.has_credit_card &&
              this.use_existing_credit_card) ||
             !this.invalid_credit_card)
    },

    purchase_params (){
      var purchase_params = { lcc : parseInt(this.lcc) };
      if(!this.has_credit_card || !this.use_existing_credit_card)
        purchase_params.credit_card = this.credit_card_params;

      return purchase_params;
    }
  },

  methods : {
    on_ok () {
      this.$store.commit('set_is_purchasing', true);
      this.purchase(this.purchase_params)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    success (){
      this.$store.commit('set_is_purchasing', false);
      this.get_user();

      const msg = "You have successfully purchased: " + this.lcc + " LCC";
      this.show_modal({
        id : 'purchased_lcc',
        message : msg
      });
    },

    failed(err){
      this.$store.commit('set_is_purchasing', false);

      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'purchase_lcc_error',
        message : 'Could not purchase LCC: ' + msg,
        is_error : true
      })
    }
  }
}
</script>

<style scoped>
.header{
  margin-top: 20px;
  font-weight: bold;
}

input, select{
  /* margin-left: 10px; */
  margin-top: 8px;
  width: 100%;
  height: 50px;
  border-radius: 5px;
}

#credit_card_cvc{
  width: 100%;
}

#credit_card_expiration_wrapper{
  display: flex;
}

#credit_card_expiration_wrapper select{
  width: 50%;
}

#output{
  margin-bottom: 0;
}
</style>
