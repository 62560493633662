<template>
  <div id="recent_posts">
    <div class="h1">{{title}}</div>

    <SectionInfo v-if="section_info">
      Recently from the blog
    </SectionInfo>

    <b-row>
      <b-col xl="6" class="py-3" v-for="post in posts" :key="post.path">
        <BlogCard :path="post.path" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HasPosts from '@/mixins/HasPosts'
import SectionInfo from '@/components/SectionInfo'
import BlogCard from '@/components/BlogCard'

export default {
  name: 'RecentPosts',

  mixins : [HasPosts],

  components: {
    BlogCard,
    SectionInfo
  },

  props : {
    title : {
      type : String,
      default : 'Recent Posts'
    },

    section_info : {
      type : Boolean,
      default : false
    }
  },

  data : function() {
    return {
      num : 4
    }
  },

  computed : {
    posts : function(){
      return this.sorted_enabled_posts.slice(0, this.num);
    }
  }
}
</script>

<style scoped>
#recent_posts .h1 {
  font-weight: 900;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 2rem;
}

#main_layout.sm #recent_posts .h1,
#main_layout.xs #recent_posts .h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}
</style>
