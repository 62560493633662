<template>
  <b-row>
    <b-col id="corporation">
      <template v-if="part_of_corporation">
        <h1>
          {{owns_corporation ? 'Owner' : 'Member'}}
          of {{corporation.title}}
        </h1>

        <b-button id="leave_corporation"
                  variant="danger"
                  @click="$bvModal.show('leave_corporation_modal')">
          {{owns_corporation ? 'Dissove' : 'Leave'}}
        </b-button>
      </template>

      <template v-else>
        <h1>You are not part of a corporation</h1>

        <b-button id="form_corporation"
                  variant="primary"
                  v-b-modal.form_corporation_modal>
          Form One
        </b-button>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'

export default {
  name : 'Corporation',
  mixins : [Authentication]
}
</script>

<style scoped>
#corporation{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

h1 {
  text-align: center;
  font-style: italic;
}

#form_corporation {
  background: var(--theme-color12) !important;
  margin-top: 25px;
}

#leave_corporation {
  background: #bd2130 !important;
  margin-top: 25px;
}
</style>
