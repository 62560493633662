<template>
  <div class="warn_box">
    <img v-if="show_icon" src="@/assets/images/icons/warning.svg" alt="warning" />

    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default{
  name : 'WarnBox',

  props : {
    show_icon : {
      type : Boolean,
      default : true
    }
  }
}
</script>

<style scoped>
.warn_box{
  background: var(--theme-color20);
  color: var(--theme-color19);
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.warn_box img{
  width: 25px;
  height: 25px;
  margin: 0 15px;
}

.warn_box div{
  flex-grow: 1;
}
</style>
