<template>
  <div class="section_info">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionInfo',
}
</script>

<style scoped>
.section_info{
  color: var(--theme-color14);
  line-height: 1.5rem;
  margin: 15px 0 0 5px;
  padding-left: 5px;
  border-left: 5px solid var(--theme-color9);
}
</style>
