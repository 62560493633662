<template>
  <b-container id="profile_tab">
    <Username />

    <hr />

    <Email />

    <hr />

    <Password />

    <hr />

    <LCC />

    <hr />

    <Status />

    <Timestamps />
  </b-container>
</template>

<script>
import Username from './profile/Username'
import Email from './profile/Email'
import Password from './profile/Password'
import LCC from './profile/LCC'
import Status from './profile/Status'
import Timestamps from './profile/Timestamps'

// TODO mailing list control

export default {
  name: 'ProfileTab',

  components : {
    Username,
    Email,
    Password,
    LCC,
    Status,
    Timestamps
  }
}
</script>

<style scoped>
#profile_tab{
  margin-top: 50px;
}
</style>
