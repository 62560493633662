<template>
  <b-row no-gutters>
    <b-col>
      <div class="header">LCC</div>

      <div class="content">
        {{account_lcc.toLocaleString()}}
      </div>
    </b-col>

    <b-col cols="8"
           id="lcc_controls" class="button_col">
      <!--<b-button class="update"
                :disabled="withdraw_disabled"
                v-b-modal.withdraw_lcc_modal>
        Withdraw
      </b-button>

      <b-button class="update"
                v-b-modal.deposit_lcc_modal>
        Deposit
      </b-button>-->

      <b-button class="update"
                :disabled="is_purchasing"
                v-b-modal.purchase_lcc_modal
                v-if="privilege('early_access', 'boolean')">
        <template v-if="is_purchasing">
          <b-spinner />
        </template>

        <template v-else>
          Purchase
        </template>
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'

export default {
  name: 'LCC',

  mixins : [Authentication],

  computed : {
    is_purchasing () {
      return this.$store.state.is_purchasing;
    },

    withdraw_disabled () {
      return this.account_lcc == 0;
    }
  }
}
</script>

<style scoped>
#main_layout.xs #lcc_controls{
  flex-direction: column;
}

#lcc_controls button{
  width: 100px;
  justify-content: center;
  margin: 5px;
  margin-right: 0;
}

#main_layout.xs #lcc_controls button{
  align-self: flex-end;;
}
</style>
