import util from './util';

export function vue_init(Vue){
  // Format date
  Vue.filter('format_date', function(value, opts) {
    return util.format_date(value, opts)
  });

  // Shorten value by specified number of chars
  Vue.filter('shorten', function(value, chars) {
    if(value.length <= chars) return "";
    return value.substr(0, value.length - chars);
  });
}
