<template>
  <b-row>
    <b-col>
      <b-row class="no_property_records" v-if="have_no_association_requests">
        <b-col>
          <i>You currently do not have any outstanding claims.</i>
        </b-col>
      </b-row>

      <div class="property_records" v-else>
        <b-row class="property_header">
          <b-col>
            Accounts Claims:
          </b-col>
        </b-row>

        <template v-if="mq_gt_xs">
          <b-row>
            <b-col class="property_column_header">
              Address
            </b-col>

            <b-col cols="3"
                   id="verification1_header"
                   class="property_column_header">
              Verification 1
            </b-col>

            <b-col cols="3"
                   id="verification2_header"
                   class="property_column_header">
              Verification 2
            </b-col>

            <b-col cols="3" class="property_column_header">
              Exipration
            </b-col>
          </b-row>

          <b-row v-for="association_request in association_requests"
                 :key="association_request.id"
                 class=association_request_row>
            <b-col>
              <span class="association_request_id" @click="show_claim(association_request)">
                {{association_request.AccountId.substr(0, mq_lte_md ? 13 : 20)}}...
              </span>
            </b-col>

            <b-col cols="3" class="verification">
              {{association_request.amount1}}
            </b-col>

            <b-col cols="3" class="verification">
              {{association_request.amount2}}
            </b-col>

            <b-col cols="3" class="expiration">
              {{new Date(Date.parse(association_request.expires)).toLocaleString()}}
            </b-col>
          </b-row>
        </template>

        <template v-else>
          <b-row v-for="association_request in association_requests"
                 :key="association_request.id"
                 class="association_request_row">
            <b-col>
              <b-row>
                <b-col class="property_column_header">
                  Account
                </b-col>

                <b-col>
                  <span class="association_request_id" @click="show_claim(association_request)">
                    {{association_request.AccountId.substr(0, 13)}}...
                  </span>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="property_column_header">
                  Verification 1
                </b-col>

                <b-col>
                  {{association_request.amount1}}
                </b-col>
              </b-row>

              <b-row>
                <b-col class="property_column_header">
                  Verification 2
                </b-col>

                <b-col>
                  {{association_request.amount2}}
                </b-col>
              </b-row>

              <b-row>
                <b-col class="property_column_header">
                  Expires
                </b-col>

                <b-col>
                  {{new Date(Date.parse(association_request.expires)).toLocaleString()}}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Authentication from '@/mixins/authentication'

export default {
  name: 'AccountAssociations',
  mixins : [Authentication],

  methods : {
    show_claim (claim) {
      this.$store.commit('set_last_claim', claim)
      this.$bvModal.show('claim_verification_modal')
    }
  }
}
</script>

<style scoped>
#main_layout.xs .association_request_row{
  padding-bottom: 15px;
  border-bottom: 1px solid var(--theme-color10);
}

#main_layout.xs .association_request_row:last-of-type{
  border-bottom: unset;
}

#main_layout.sm .property_column_header,
#main_layout.xs .property_column_header{
  font-size: 14px;
}

.association_request_id{
  color: var(--theme-color9);
  cursor: pointer;
}

#verification1_header,
#verification2_header,
.verification{
  text-align: right;
}

.expiration{
  font-size: 14px;
}
</style>
