<template>
  <b-modal id="deposit_lcc_modal"
           ref="deposit_lcc_modal"
           ok-only centered>
    <ModalWrapper>
      <div class="profile_modal_header">
        Deposit LCC
      </div>

      <div>
        Send a payment for the amount of LCC you want to deposit to
      </div>

      <div id="required_info">
        <span class="profile_modal_required">*</span>
        <span> Required field</span>
      </div>

      <div id="destination">
        <span class="profile_modal_label">Address</span><span class="profile_modal_required"> *</span>
        <CopyBox :class="$mq">{{issuer_address}}</CopyBox>
      </div>

      <div id="destination_tag">
        <span class="profile_modal_label">Destination Tag</span><span class="profile_modal_required"> *</span>
        <CopyBox :class="$mq">{{account_destination_tag}}</CopyBox>
      </div>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import CopyBox from '@/components/CopyBox'
import Authentication from '@/mixins/authentication'

import lc from '@/config/lc'

export default {
  name : 'DepositLCC',

  mixins : [Authentication],

  components : {
    ModalWrapper,
    CopyBox
  },

  computed : {
    issuer_address () {
      return lc.wallets.operational;
    }
  }
}
</script>

<style scoped>
#destination{
  margin-bottom: 15px;
}

#required_info{
  text-align: right;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}
</style>

<style>
#deposit_lcc_modal___BV_modal_header_{
  display: none;
}

#deposit_lcc_modal___BV_modal_footer_ button{
  width: 100%;
  font-weight: bold;
  background: var(--theme-color9);
  color: var(--theme-color0);
}

</style>
