<template>
  <b-modal id="form_corporation_modal"
           ref="form_corporation_modal"
           centered
           :ok-disabled="!title_specified || !title_valid"
           @ok="on_ok" @shown="reset">
    <ModalWrapper>
      <div class="profile_modal_header">
        Form Corporation
      </div>

      <b-form-group label-for="title"
                    :invalid-feedback="title_error"
                    :state="title_valid">

        <b-form-input id="title" v-model="title"
                      class="mt-1" size="lg"
                      placeholder="Title" :trim="true" />

      </b-form-group>
    </ModalWrapper>
  </b-modal>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper'

import Authentication from '@/mixins/authentication'
import ServerAPI from '@/mixins/server_api'
import TriggersModal from '@/mixins/triggers_modal'

import lc from '@/config/lc'
import util from '@/util'

export default {
  name : 'FormCorporation',

  mixins : [
    Authentication,
    ServerAPI,
    TriggersModal
  ],

  components : {
    ModalWrapper
  },

  data () {
    return {
      title : null
    }
  },

  computed : {
    title_specified (){
      return !!this.title;
    },

    title_too_short () {
      return this.title_specified &&
             this.title.length < lc.min_corporation_title_length;
    },

    title_too_long () {
      return this.title_specified &&
             this.title.length > lc.max_corporation_title_length;
    },

    title_has_invalid_chars () {
      return this.title_specified &&
             lc.invalid_chars.some((c) => this.title.includes(c));
    },

    title_valid (){
      return this.title_specified &&
            !this.title_too_short &&
            !this.title_too_long  &&
            !this.title_has_invalid_chars;
    },

    title_error () {
      if(this.title_too_short) return "Title is too short";
      if(this.title_too_long) return "Title is too long";
      if(this.title_has_invalid_chars) return "Title has invalid characters";
      return null;
    }
  },

  methods : {
    on_ok () {
      this.form_corporation(this.title)
          .then(this.success.bind(this))
          .catch(this.failed.bind(this))
    },

    reset (){
      this.title = null;
    },

    success () {
      this.get_user();
    },

    failed (err) {
      const msg = util.capitalize(err.body.error)
      this.show_modal({
        id : 'form_corporation_error',
        message : msg,
        is_error : true
      });
    },
  }
}
</script>
