<template>
  <b-overlay no-center
             :show="show_overlay"
             class="gallery_image_wrapper">
    <enlargeable-image
         v-if="expandable"
         :src="src" />

    <img v-else
         :src="src"
         @mouseover="show_overlay = true"
         alt="Ledger City Screenshot" />

    <div v-if="subtext" class="subtext">
      {{text}}
    </div>

    <template v-slot:overlay>
      <div @mouseleave="show_overlay = false"
           class="overlay_content">
        <div class="img_title"><h4>{{text}}</h4></div>
        <div class="ledger_city"><h5>Ledger City</h5></div>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import gallery from '../assets/data/gallery.json'

export default {
  name: 'GalleryImage',

  props : {
    id : {
      type : String,
      required : true
    },

    subtext : {
      type : Boolean,
      default : false
    },

    expandable : {
      type : Boolean,
      default : false
    }
  },

  data : function(){
    return {
      show_overlay : false
    }
  },

  computed : {
    meta : function(){
      return gallery.find((g) => g.id == this.id);
    },

    src : function(){
      return require("../assets/images/gallery/_mq/" + this.id + "-" + this.$mq + ".webp");
    },

    text : function(){
      return this.meta.text;
    }
  }
}
</script>

<style scoped>
.gallery_image_wrapper{
  height: 100%;
}

.subtext{
  text-align: center;
  letter-spacing: 3px;
  font-size: 22px;
}

#main_layout.sm .subtext{
  font-size: 16px;
}

#main_layout.xs .subtext{
  font-size: 14px;
}

.overlay_content{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_title{
  color: var(--theme-color2);
}

.ledger_city{
  color: var(--theme-color3);
}
</style>

<style>
.gallery_image_wrapper img{
  height: 100%;
  width: 100%;
}

.gallery_image_wrapper .slot{
  height: 100%;
  width: 100%;
}
</style>
