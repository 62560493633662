<template>
  <div id="gallery">
    <div id="header">
      <h2>Gallery</h2>

      <div id="controls">
        <img src="@/assets/images/icons/left-arrow.svg" @click="prev" alt="previous" />
        <img src="@/assets/images/icons/right-arrow.svg" @click="next" alt="next" />
      </div>
    </div>

    <SectionInfo>
      Blockchain is changing the world of finance. Now it's time to revolutionalize the virtual world... one player at a time...
    </SectionInfo>

    <VueSlickCarousel v-bind="settings" ref="carousel">
     <GalleryImage v-for="image in images"
                   :key="image"
                   :id="image" />
    </VueSlickCarousel>

    <b-button id="gallery_view_more"
              to="/gallery">
      VIEW MORE
    </b-button>
  </div>
</template>

<script>
import SectionInfo  from '@/components/SectionInfo'
import GalleryImage from '@/components/GalleryImage'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Gallery',

  components : {
    VueSlickCarousel,
    SectionInfo,
    GalleryImage
  },

  data () {
    return {
      images : ["1", "2", "3", "4", "5", "6", "7"]
    }
  },

  computed : {
    slidesToShow () {
      return this.mq_xs ? 1 :
             this.mq_lte_sm ? 2 : 3;
    },

    slidesToScroll () {
      return this.mq_lte_md ? 1 :
             this.mq_lte_lg ? 2 : 3;
    },

    settings () {
      return {
        arrows: false,
        focusOnSelect: true,
        infinite: true,
        slidesToShow : this.slidesToShow,
        slidesToScroll: this.slidesToScroll,
        autoplay: false,
        speed: 500,
        autoplaySpeed: 5000,
      }
    },
  },

  methods : {
    prev () {
      this.$refs.carousel.prev();
    },

    next () {
      this.$refs.carousel.next();
    },
  }
}
</script>

<style scoped>
#gallery{
  margin: 25px 0;
  padding: 50px;
}

#main_layout.xs #gallery{
  padding: 15px;
}

#gallery #header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2{
  font-weight: 900;
  font-size: 72px;
}

#main_layout.sm h2,
#main_layout.xs h2{
  font-size: 48px;
}

#controls{
  display: flex;
  justify-content: flex-end;
}

#controls img{
  width: 50px;
  height: 50px;
  cursor: pointer;
}

#main_layout.xs #controls img{
  width: 45px;
  height: 45px;
}

#gallery_view_more{
  margin-top: 15px;
  margin-left: 25px;
  background: var(--theme-color0);
  color: var(--theme-color9);
  border: 1px solid var(--theme-color9);
  border-radius: 41px;
  font-weight: bold;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main_layout.sm #gallery_view_more,
#main_layout.xs #gallery_view_more{
  margin: auto;
}
</style>

<style>
#gallery .slick-slider .gallery_image_wrapper{
  width: 445px;
  padding: 25px;
}
</style>
