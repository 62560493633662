<template>
  <b-row id="claim">
    <b-col class="d-flex justify-content-center">
      <b-button class="edit"
                v-b-modal.claim_account_modal>
        Claim Account
      </b-button>
    </b-col>
      
  </b-row>
</template>

<script>
export default {
  name: 'Claim'
}
</script>

<style scoped>
#profile #claim{
  flex-grow: 1;
  align-items: start;
  justify-content: center;
}
</style>
